import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const GivingBackIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M6.883 15.812a1.66 1.66 0 0 1 .925-.28h6.525c.367 0 .667.3.667.667 0 .367-.3.667-.667.667h-3.666c-.554 0-1 .446-1 1s.446 1 1 1h5.666c.184 0 .367-.05.525-.15l3.896-2.396c.13-.08.28-.12.43-.12h.308a.512.512 0 0 1 .279.94l-3.983 2.58a3.048 3.048 0 0 1-1.634.48H1c-.554 0-1 .445-1 1 0 .553.446 1 1 1h15.154c.963 0 1.909-.28 2.717-.805l3.983-2.58a2.513 2.513 0 0 0-1.367-4.62h-.308c-.52 0-1.033.146-1.479.417l-2.7 1.67V16.2a2.67 2.67 0 0 0-2.667-2.666H7.808c-.725 0-1.433.212-2.033.616l-3.08 2.05H1c-.554 0-1 .446-1 1s.446 1 1 1h2c.196 0 .392-.058.554-.166l3.33-2.221Zm9.668-13.087a3.129 3.129 0 0 0-2.226-.922h-.004c-.84 0-1.633.328-2.23.923l-.22.218-.218-.218A3.134 3.134 0 0 0 9.422 1.8a3.16 3.16 0 0 0-3.156 3.155c0 .842.329 1.634.925 2.23l4.68 4.68 4.68-4.68a3.134 3.134 0 0 0 .925-2.23c0-.843-.329-1.636-.926-2.232h.001Zm-.829 2.231c0 .375-.146.727-.41.991l-3.44 3.439L8.43 5.947a1.402 1.402 0 0 1 .99-2.392c.369 0 .73.15.992.412l1.458 1.459 1.458-1.461a1.402 1.402 0 0 1 2.392.99l.001.001Z"
      fill="currentColor"
    />
  </IconBase>
);
