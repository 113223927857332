import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const DeleteIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M9.478 2.419 8.582 3.75h6.836l-.896-1.331a.385.385 0 0 0-.316-.169H9.79a.374.374 0 0 0-.316.169h.005Zm6.93-1.247 1.73 2.578h3.29a1.125 1.125 0 1 1 0 2.25h-.377v14.25c0 2.072-1.687 3.75-3.771 3.75H6.72c-2.084 0-3.771-1.678-3.771-3.75V6H2.57a1.126 1.126 0 1 1 0-2.25h3.291l1.73-2.583A2.654 2.654 0 0 1 9.79 0h4.417c.882 0 1.707.44 2.197 1.167l.005.005ZM5.21 6v14.25c0 .83.675 1.5 1.509 1.5h10.56c.834 0 1.509-.67 1.509-1.5V6H5.21Zm3.772 3v9.75a.754.754 0 0 1-1.509 0V9a.754.754 0 0 1 1.509 0Zm3.771 0v9.75a.754.754 0 0 1-1.508 0V9a.754.754 0 0 1 1.508 0Zm3.772 0v9.75a.754.754 0 0 1-1.509 0V9a.754.754 0 0 1 1.509 0Z"
      fill="currentColor"
    />
  </IconBase>
);
