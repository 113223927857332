import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const FacebookIcon = (props: IconProps) => {
  const clipId = useId();

  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M21.429 0H2.57A2.571 2.571 0 0 0 0 2.571V21.43A2.571 2.571 0 0 0 2.571 24h7.353v-8.16H6.55V12h3.375V9.073c0-3.33 1.982-5.169 5.018-5.169 1.454 0 2.974.26 2.974.26V7.43h-1.675c-1.65 0-2.165 1.025-2.165 2.075V12h3.684l-.589 3.84h-3.095V24h7.353A2.57 2.57 0 0 0 24 21.429V2.57A2.571 2.571 0 0 0 21.429 0Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
