import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const SleepIcon = (props: IconProps) => {
  const clipId = useId();

  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M2.25 12A9.749 9.749 0 0 1 12 2.25c1.44 0 2.808.314 4.036.872.567.258 1.233.01 1.49-.558a1.124 1.124 0 0 0-.557-1.49A11.999 11.999 0 0 0 12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12c0-1.767-.384-3.45-1.073-4.964a1.124 1.124 0 0 0-1.491-.558 1.124 1.124 0 0 0-.558 1.49A9.749 9.749 0 0 1 12 21.75a9.749 9.749 0 0 1-9.75-9.749ZM12 19.875a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75ZM15 6h1.19l-1.72 1.72a.754.754 0 0 0-.164.816A.753.753 0 0 0 15 9h3c.413 0 .75-.338.75-.75A.752.752 0 0 0 18 7.5h-1.19l1.72-1.72a.754.754 0 0 0 .164-.816A.753.753 0 0 0 18 4.5h-3a.752.752 0 0 0-.75.75c0 .412.338.75.75.75ZM19.5.75c0 .413.337.75.75.75h1.19l-1.72 1.72a.754.754 0 0 0-.164.816.753.753 0 0 0 .694.464h3c.413 0 .75-.338.75-.75a.752.752 0 0 0-.75-.75h-1.19l1.72-1.72a.754.754 0 0 0 .164-.816A.753.753 0 0 0 23.25 0h-3a.752.752 0 0 0-.75.75Zm-3.019 11.063c-.497 0-.933-.179-1.19-.455a.935.935 0 0 0-1.327-.042.936.936 0 0 0-.042 1.326c.68.727 1.65 1.046 2.56 1.046.909 0 1.879-.32 2.559-1.046a.94.94 0 0 0-.043-1.326.94.94 0 0 0-1.326.042c-.258.272-.694.454-1.19.454Zm-10.19-.455a.936.936 0 0 0-1.327-.042.936.936 0 0 0-.042 1.326c.68.727 1.65 1.046 2.56 1.046.909 0 1.879-.32 2.559-1.046a.94.94 0 0 0-.043-1.326.94.94 0 0 0-1.326.042c-.258.272-.694.454-1.19.454-.498 0-.934-.178-1.191-.454Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
