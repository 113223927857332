import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const StarFilledIcon = (props: IconProps) => {
  const clipId = useId();
  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`}>
        <path
          d="m11.998 20.437-6.265 3.431c-.327.202-.773.17-1.114-.08-.336-.248-.504-.68-.477-1.101l1.232-7.29-5.05-5.167a1.143 1.143 0 0 1-.27-1.149c.13-.403.469-.698.878-.76l6.965-1.063 3.12-6.624c.186-.388.563-.634.981-.634.419 0 .8.246.982.634l3.12 6.624 6.97 1.064c.408.06.745.356.877.76.127.407.022.853-.269 1.148l-5.055 5.167 1.195 7.29c.069.422-.1.853-.44 1.102-.337.248-.824.281-1.15.08l-6.23-3.432Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
