import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const HouseHeartIcon = (props: IconProps) => {
  const clipId = useId();

  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`} fill="currentColor">
        <path d="M12.648 1.677a1.003 1.003 0 0 0-1.296 0l-11 9.334c-.42.358-.47.987-.116 1.408.354.42.987.47 1.408.117l1.025-.867v7.77a3.332 3.332 0 0 0 3.333 3.334h12a3.332 3.332 0 0 0 3.333-3.333v-7.771l1.021.867a.998.998 0 0 0 1.408-.117 1.001 1.001 0 0 0-.116-1.408l-11-9.334Zm6.687 8.296v9.467c0 .737-.596 1.333-1.333 1.333h-12A1.332 1.332 0 0 1 4.67 19.44V9.973l7.333-6.22 7.333 6.22Z" />
        <path d="M16.02 11.132a2.686 2.686 0 0 0-1.911-.792h-.004c-.722 0-1.402.281-1.915.792l-.188.188-.188-.188a2.691 2.691 0 0 0-1.916-.794 2.713 2.713 0 0 0-2.71 2.71c0 .723.283 1.403.795 1.915l4.018 4.018 4.019-4.018a2.69 2.69 0 0 0 .794-1.916 2.69 2.69 0 0 0-.795-1.916Zm-.712 1.916c0 .321-.125.623-.352.85l-2.954 2.953-2.954-2.952a1.204 1.204 0 0 1 1.702-1.7L12 13.45l1.252-1.255a1.204 1.204 0 0 1 2.054.85v.002Z" />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
