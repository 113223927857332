import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const HeartFilledIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="m2.23125 14.0255 8.47035 7.9078c.3515.3281.8156.5109 1.2984.5109s.9469-.1828 1.2984-.5109l8.4703-7.9078C23.1938 12.6989 24 10.838 24 8.89266v-.27188c0-3.27656-2.3672-6.07031-5.5969-6.60937-2.1375-.35625-4.3125.34219-5.8406 1.87031L12 4.44422l-.5625-.5625C9.90938 2.3536 7.73438 1.65516 5.59688 2.01141 2.36719 2.55047 0 5.34422 0 8.62078v.27188C0 10.838.80625 12.6989 2.23125 14.0255Z"
      fill="currentColor"
    />
  </IconBase>
);
