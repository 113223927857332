import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const EnvelopeIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M3 5.13a.752.752 0 0 0-.75.75v1.036l8.086 6.637c.97.797 2.362.797 3.333 0l8.081-6.637V5.88a.752.752 0 0 0-.75-.75H3Zm-.75 4.697v8.053c0 .413.337.75.75.75h18c.413 0 .75-.337.75-.75V9.827l-6.656 5.466a4.875 4.875 0 0 1-6.188 0L2.25 9.826ZM0 5.88c0-1.655 1.345-3 3-3h18c1.655 0 3 1.345 3 3v12c0 1.655-1.345 3-3 3H3c-1.655 0-3-1.345-3-3v-12Z"
      fill="currentColor"
    />
  </IconBase>
);
