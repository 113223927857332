import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const VolumeIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="m7.142 9.96 4.671-4.15v12.808l-4.67-4.15a1.005 1.005 0 0 0-.663-.255H2.813a.334.334 0 0 1-.333-.333v-3.333c0-.184.15-.334.333-.334H6.48a.989.989 0 0 0 .662-.254Zm5.43-7.08c-.304 0-.596.113-.825.313L6.1 8.212H2.813A2.335 2.335 0 0 0 .48 10.547v3.333a2.335 2.335 0 0 0 2.333 2.333h3.288l5.646 5.021c.229.2.52.313.825.313a1.24 1.24 0 0 0 1.241-1.242V4.122a1.24 1.24 0 0 0-1.241-1.242Zm7.62 3.125a1 1 0 1 0-1.262 1.554 5.978 5.978 0 0 1 2.217 4.654c0 1.88-.863 3.555-2.217 4.659-.43.35-.492.979-.146 1.408.346.43.98.492 1.409.146a7.994 7.994 0 0 0 2.954-6.208 7.994 7.994 0 0 0-2.954-6.209v-.004Zm-2.52 3.104a1 1 0 1 0-1.263 1.554c.45.367.738.925.738 1.55a2.01 2.01 0 0 1-.738 1.555c-.429.35-.491.979-.146 1.408.346.429.98.492 1.409.146a4.02 4.02 0 0 0 1.475-3.109c0-1.254-.58-2.37-1.475-3.104Z"
      fill="currentColor"
    />
  </IconBase>
);
