import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const RefreshedIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M5.093 4.602a.935.935 0 0 0-1.338 0L.278 8.12a.965.965 0 0 0 0 1.356.938.938 0 0 0 1.338 0l1.855-1.88v7.282c0 1.944 1.555 3.52 3.473 3.52h5.372a.951.951 0 0 0 .947-.96.951.951 0 0 0-.947-.96H6.948a1.589 1.589 0 0 1-1.578-1.6V7.597l1.854 1.88a.935.935 0 0 0 1.338 0 .968.968 0 0 0 0-1.356l-3.469-3.52Zm6.591 1a.951.951 0 0 0-.947.96c0 .531.422.96.947.96h5.368c.872 0 1.579.715 1.579 1.599v7.282l-1.855-1.88a.935.935 0 0 0-1.338 0 .968.968 0 0 0 0 1.356l3.473 3.519a.935.935 0 0 0 1.338 0l3.473-3.52a.965.965 0 0 0 0-1.355.938.938 0 0 0-1.338 0l-1.855 1.88V9.12c0-1.944-1.555-3.52-3.473-3.52h-5.372Z"
      fill="currentColor"
    />
  </IconBase>
);
