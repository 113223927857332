import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const UserCircleIcon = (props: IconProps) => {
  const clipId = useId();

  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M19.055 18.731A6.003 6.003 0 0 0 13.5 15h-3a6.003 6.003 0 0 0-5.555 3.731A9.73 9.73 0 0 1 2.25 12 9.749 9.749 0 0 1 12 2.25 9.749 9.749 0 0 1 21.75 12a9.73 9.73 0 0 1-2.695 6.731Zm-1.88 1.533A9.691 9.691 0 0 1 12 21.75a9.72 9.72 0 0 1-5.18-1.486 3.752 3.752 0 0 1 3.68-3.014h3a3.752 3.752 0 0 1 3.68 3.014h-.005ZM12 24a12 12 0 1 0 0-24 12 12 0 0 0 0 24Zm0-12.75a1.875 1.875 0 1 1 0-3.75 1.875 1.875 0 0 1 0 3.75ZM7.875 9.375a4.125 4.125 0 1 0 8.25 0 4.125 4.125 0 0 0-8.25 0Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
