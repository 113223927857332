import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const MinusIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M24 11.945a1.38 1.38 0 0 1-1.385 1.384H1.385a1.384 1.384 0 1 1 0-2.769h21.23c.768 0 1.385.623 1.385 1.385Z"
      fill="currentColor"
    />
  </IconBase>
);
