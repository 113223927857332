import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const TextIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M2.61147 3.01137V5.5828C2.61147 6.2953 2.03825 6.86851 1.32575 6.86851C0.613253 6.86851 0.0400391 6.2953 0.0400391 5.5828V2.5828C0.0400391 1.39887 0.998968 0.439941 2.1829 0.439941H21.8972C23.0811 0.439941 24.04 1.39887 24.04 2.5828V5.5828C24.04 6.2953 23.4668 6.86851 22.7543 6.86851C22.0418 6.86851 21.4686 6.2953 21.4686 5.5828V3.01137H13.3258V21.8685H15.8972C16.6097 21.8685 17.1829 22.4417 17.1829 23.1542C17.1829 23.8667 16.6097 24.4399 15.8972 24.4399H8.1829C7.4704 24.4399 6.89718 23.8667 6.89718 23.1542C6.89718 22.4417 7.4704 21.8685 8.1829 21.8685H10.7543V3.01137H2.61147Z"
      fill="currentColor"
    />
  </IconBase>
);
