import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const MuteIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="m6.662 9.96 4.671-4.15v12.808l-4.67-4.15A1.005 1.005 0 0 0 6 14.212H2.333A.334.334 0 0 1 2 13.88v-3.333c0-.184.15-.334.333-.334H6a.989.989 0 0 0 .662-.254Zm5.43-7.08c-.305 0-.596.113-.825.313L5.62 8.212H2.333A2.335 2.335 0 0 0 0 10.547v3.333a2.335 2.335 0 0 0 2.333 2.333h3.288l5.646 5.021c.229.2.52.313.825.313a1.24 1.24 0 0 0 1.241-1.242V4.122a1.24 1.24 0 0 0-1.241-1.242Zm5.616 5.625a1 1 0 0 0-1.413 1.412l2.293 2.292-2.292 2.292a1 1 0 0 0 1.413 1.412L20 13.622l2.292 2.291a1 1 0 0 0 1.413-1.412l-2.293-2.292 2.292-2.291a1 1 0 0 0-1.413-1.412L20 10.795l-2.292-2.291Z"
      fill="currentColor"
    />
  </IconBase>
);
