import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const ArrowRightIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M23.67 12.618a1.12 1.12 0 0 0 0-1.589L17.295 4.65a1.124 1.124 0 0 0-1.589 1.589l4.453 4.453-19.034.005c-.623 0-1.125.501-1.125 1.125 0 .623.502 1.124 1.125 1.124h19.034L15.706 17.4a1.124 1.124 0 0 0 1.589 1.589l6.375-6.37Z"
      fill="currentColor"
    />
  </IconBase>
);
