import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const InfoIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M12 23.999a12 12 0 0 0 0-24c-6.628 0-12 5.373-12 12s5.372 12 12 12Zm-1.875-8.25h1.125v-3h-1.125A1.122 1.122 0 0 1 9 11.626C9 11 9.5 10.5 10.125 10.5h2.25c.623 0 1.124.5 1.124 1.125v4.125h.375c.624 0 1.125.5 1.125 1.124 0 .624-.5 1.125-1.125 1.125h-3.75A1.122 1.122 0 0 1 9 16.874c0-.624.5-1.124 1.125-1.124ZM11.999 6a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </IconBase>
);
