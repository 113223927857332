import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const TagPriceIcon = (props: IconProps) => {
  const clipId = useId();
  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`} fill="currentColor">
        <path d="M10.835 0c.933 0 1.827.368 2.486 1.026l9.656 9.656a3.51 3.51 0 0 1 0 4.965l-7.325 7.324a3.51 3.51 0 0 1-4.965 0l-9.656-9.656A3.482 3.482 0 0 1 0 10.835V2.633A2.634 2.634 0 0 1 2.633 0h8.202ZM2.633 10.835c0 .231.094.456.258.62l9.656 9.656c.34.34.9.34 1.24 0l7.324-7.324a.88.88 0 0 0 0-1.24l-9.655-9.656a.881.881 0 0 0-.62-.258H2.633v8.202ZM6.145 4.39a1.756 1.756 0 1 1 0 3.512 1.756 1.756 0 0 1 0-3.512Z" />
        <path d="M12.572 6.632v.6c.412.051.824.166 1.231.28.08.021.157.042.238.067a.914.914 0 0 1 .64 1.125.923.923 0 0 1-1.129.639c-.068-.017-.131-.038-.2-.055a10.89 10.89 0 0 0-.895-.224c-.56-.106-1.21-.055-1.731.169-.467.203-.853.693-.323 1.031.416.267.925.402 1.41.533.101.025.199.055.296.08.662.186 1.507.427 2.14.858.823.563 1.209 1.476 1.026 2.457-.174.947-.836 1.568-1.63 1.889a3.95 3.95 0 0 1-1.069.262v.643a.916.916 0 0 1-1.833 0v-.736c-.616-.14-1.218-.334-1.817-.528a.911.911 0 0 1-.581-1.155.918.918 0 0 1 1.158-.579c.106.034.213.072.319.106.48.16.971.325 1.464.406.721.106 1.299.042 1.677-.11.509-.203.75-.807.25-1.145-.429-.292-.96-.436-1.464-.571a6.897 6.897 0 0 1-.29-.08c-.64-.182-1.442-.406-2.045-.79-.827-.529-1.248-1.408-1.07-2.385.17-.922.892-1.535 1.656-1.864.233-.102.484-.182.743-.241v-.681a.916.916 0 0 1 1.833 0h-.004Z" />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
