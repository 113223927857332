import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const PhoneIcon = (props: IconProps) => {
  const clipId = useId();

  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M17.616 12.9a1.874 1.874 0 0 0-2.19.534l-1.556 1.903a14.373 14.373 0 0 1-5.207-5.208l1.898-1.551c.647-.53.867-1.42.534-2.19l-2.25-5.25A1.872 1.872 0 0 0 6.731.043l-5.25 1.125A1.872 1.872 0 0 0 0 2.999c0 10.838 8.213 19.763 18.755 20.883.46.047.918.085 1.387.103h.005c.286.01.567.02.853.02.886 0 1.65-.62 1.833-1.482l1.125-5.25a1.872 1.872 0 0 0-1.097-2.114l-5.25-2.25.005-.01Zm3.08 8.85C10.583 21.585 2.413 13.415 2.254 3.303l4.65-.998L8.92 7.012 7.237 8.39a2.25 2.25 0 0 0-.52 2.869 16.593 16.593 0 0 0 6.028 6.028 2.25 2.25 0 0 0 2.87-.52l1.377-1.683 4.706 2.015-1.003 4.65Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
