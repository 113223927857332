import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const BellFillIcon = (props: IconProps) => (
  <IconBase {...props} viewBox="0 0 20 20">
    <path
      d="M10 0c-.692 0-1.25.559-1.25 1.25v.7a5.94 5.94 0 0 0-5 5.862v1.305a7.82 7.82 0 0 1-1.712 4.88l-.582.73a.935.935 0 0 0 .73 1.523h15.626a.938.938 0 0 0 .73-1.523L17.96 14a7.838 7.838 0 0 1-1.71-4.883V7.812a5.94 5.94 0 0 0-5-5.863V1.25C11.25.559 10.69 0 10 0Zm2.5 17.5h-5c0 .664.26 1.3.73 1.77A2.5 2.5 0 0 0 10 20a2.5 2.5 0 0 0 1.769-.73 2.5 2.5 0 0 0 .73-1.77Z"
      fill="currentColor"
    />
  </IconBase>
);
