import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const ShareIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M12.808.33a1.132 1.132 0 0 0-1.599 0l-6.043 6a1.115 1.115 0 0 0 0 1.589c.444.436 1.16.44 1.6 0l4.103-4.078v11.535c0 .623.505 1.125 1.132 1.125.628 0 1.133-.502 1.133-1.125V3.84l4.103 4.078c.444.44 1.16.44 1.6 0a1.12 1.12 0 0 0 0-1.59L12.807.33ZM3.704 16.126C3.704 15.502 3.2 15 2.572 15c-.627 0-1.132.501-1.132 1.125v3.75C1.44 22.152 3.299 24 5.591 24h12.83c2.293 0 4.151-1.847 4.151-4.125v-3.75c0-.623-.505-1.124-1.132-1.124-.627 0-1.132.501-1.132 1.125v3.75a1.88 1.88 0 0 1-1.887 1.874H5.591a1.88 1.88 0 0 1-1.887-1.875v-3.75Z"
      fill="currentColor"
    />
  </IconBase>
);
