import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const ComputerIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M21.333 3.44H2.667c-.367 0-.667.3-.667.667v8h20v-8c0-.367-.3-.667-.667-.667ZM24 12.107v4a2.67 2.67 0 0 1-2.667 2.666H15.18l.333 2h2.155c.554 0 1 .446 1 1s-.446 1-1 1H6.333c-.554 0-1-.446-1-1s.446-1 1-1h2.155l.333-2H2.667A2.67 2.67 0 0 1 0 16.107v-12A2.67 2.67 0 0 1 2.667 1.44h18.666A2.67 2.67 0 0 1 24 4.107v8Zm-22 2v2c0 .366.3.666.667.666h18.666c.367 0 .667-.3.667-.666v-2H2Zm8.512 6.666h2.971l-.333-2h-2.304l-.334 2Z"
      fill="currentColor"
    />
  </IconBase>
);
