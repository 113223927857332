import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const DropletSlashIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M12 20.2397C13.4212 20.2397 14.715 19.6923 15.6787 18.7923L17.1037 19.9173C15.8024 21.226 13.995 22.0397 12 22.0397C8.02507 22.0397 4.80013 18.8148 4.80013 14.8398C4.80013 13.8011 5.25012 12.4811 5.91761 11.1049L7.36883 12.2486C7.32383 12.3499 7.27883 12.4511 7.23383 12.5486C6.78759 13.5836 6.59634 14.3561 6.59634 14.8398C6.59634 17.821 9.01505 20.2397 11.9963 20.2397H12ZM18.9449 16.7411L23.6548 20.431C24.0448 20.7385 24.116 21.3047 23.8085 21.6947C23.501 22.0847 22.9348 22.156 22.5448 21.8485L0.345205 4.44878C-0.0447884 4.14128 -0.116037 3.57504 0.191457 3.18505C0.498952 2.79506 1.06519 2.72381 1.45519 3.0313L7.71758 7.93996C9.0263 5.88875 10.4175 4.07753 11.0513 3.2788C11.2725 2.99755 11.6063 2.84005 11.9663 2.84005H12.0337C12.3937 2.84005 12.7275 2.99755 12.9525 3.2788C14.3175 5.00377 19.1999 11.4199 19.1999 14.8398C19.1999 15.4961 19.1099 16.1373 18.9449 16.7411ZM17.3587 15.4998C17.3849 15.2823 17.3999 15.0648 17.3999 14.8398C17.3999 14.3524 17.2087 13.5836 16.7624 12.5486C16.3312 11.5512 15.7274 10.4562 15.0562 9.37244C13.9687 7.61747 12.7687 5.99 12 4.98502C11.2763 5.92625 10.1738 7.42247 9.1388 9.0537L17.3587 15.4961V15.4998ZM8.40006 14.5398C8.40006 14.0936 8.72631 13.7224 9.1538 13.6511L10.2 14.4724C10.2 14.4949 10.2038 14.5173 10.2038 14.5361C10.2038 15.6948 11.145 16.6361 12.3037 16.6361C12.8025 16.6361 13.2037 17.0373 13.2037 17.536C13.2037 18.0348 12.8025 18.436 12.3037 18.436C10.1513 18.436 8.40381 16.6886 8.40381 14.5361L8.40006 14.5398Z"
      fill="currentColor"
    />
  </IconBase>
);
