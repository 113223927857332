import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const GripIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M1.38462 5.76001C.617308 5.76001 0 6.37732 0 7.14463c0 .7673.617308 1.38461 1.38462 1.38461H22.6154c.7673 0 1.3846-.61731 1.3846-1.38461 0-.76731-.6173-1.38462-1.3846-1.38462H1.38462Zm0 10.15389C.617308 15.9139 0 16.5312 0 17.2985c0 .7673.617308 1.3846 1.38462 1.3846H22.6154c.7673 0 1.3846-.6173 1.3846-1.3846 0-.7673-.6173-1.3846-1.3846-1.3846H1.38462Z"
      fill="currentColor"
    />
  </IconBase>
);
