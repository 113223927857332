import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const ExternalShareIcon = (props: IconProps) => {
  const clipId = useId();

  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M14.8117 1.12214C14.8117 1.74399 15.312 2.24428 15.9339 2.24428H20.7076L10.2764 12.6708C9.83691 13.1103 9.83691 13.821 10.2764 14.2558C10.7159 14.6907 11.4266 14.6953 11.8614 14.2558L22.288 3.8293V8.60307C22.288 9.22492 22.7883 9.7252 23.4101 9.7252C24.032 9.7252 24.5322 9.22492 24.5322 8.60307V1.12214C24.5322 0.500287 24.032 0 23.4101 0H15.9339C15.312 0 14.8117 0.500287 14.8117 1.12214ZM3.96438 1.49619C2.1035 1.49619 0.597961 3.00172 0.597961 4.8626V20.5725C0.597961 22.4334 2.1035 23.939 3.96438 23.939H19.6743C21.5352 23.939 23.0407 22.4334 23.0407 20.5725V14.5878C23.0407 13.966 22.5405 13.4657 21.9186 13.4657C21.2967 13.4657 20.7965 13.966 20.7965 14.5878V20.5725C20.7965 21.1944 20.2962 21.6947 19.6743 21.6947H3.96438C3.34253 21.6947 2.84224 21.1944 2.84224 20.5725V4.8626C2.84224 4.24075 3.34253 3.74046 3.96438 3.74046H9.94912C10.571 3.74046 11.0713 3.24018 11.0713 2.61832C11.0713 1.99647 10.571 1.49619 9.94912 1.49619H3.96438Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <rect
            fill="white"
            height="24"
            transform="translate(0.593262)"
            width="24"
          />
        </clipPath>
      </defs>
    </IconBase>
  );
};
