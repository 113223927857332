import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const BedFullIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M22.2 6V1.2c0-.66-.48-1.2-1.068-1.2H2.868C2.28 0 1.8.54 1.8 1.2V6c0 .66.48 1.2 1.068 1.2V18c-.588 0-1.068.54-1.068 1.2v2.4c0 .66.48 1.2 1.068 1.2h1.068c0 .66.48 1.2 1.068 1.2.588 0 1.068-.54 1.068-1.2H17.88c0 .66.48 1.2 1.068 1.2.588 0 1.068-.54 1.068-1.2h1.068c.636 0 1.116-.54 1.116-1.2v-2.4c0-.66-.48-1.2-1.068-1.2V7.2c.588 0 1.068-.54 1.068-1.2Zm-1.068 13.5v1.8c0 .168-.12.3-.264.3H3.144c-.144 0-.264-.132-.264-.3v-1.8c0-.168.12-.3.264-.3h17.712c.156 0 .276.132.276.3Zm-2.16-9.9c.588 0 1.068.54 1.068 1.2V18H3.948v-7.2c0-.66.48-1.2 1.068-1.2h13.956Zm-13.956-3V4.2c0-.336.24-.6.54-.6h5.364c.3 0 .54.264.54.6v2.4c0 .336-.24.6-.54.6H5.556c-.3 0-.54-.264-.54-.6Zm7.524 0V4.2c0-.336.24-.6.54-.6h5.364c.3 0 .54.264.54.6v2.4c0 .336-.24.6-.54.6h-5.376c-.288 0-.528-.264-.528-.6Zm8.052-.6h-.54V3.6c0-.66-.48-1.2-1.068-1.2H5.016c-.588 0-1.068.54-1.068 1.2V6h-.54c-.288 0-.54-.264-.54-.6V1.8c0-.336.18-.6.396-.6h17.484c.216 0 .384.264.384.6v3.6c0 .336-.252.6-.54.6Z"
      fill="currentColor"
    />
  </IconBase>
);
