import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const ChevronDownIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M23.588 8.056 12.973 18.228c-.355.3-.689.422-.972.422-.284 0-.665-.123-.922-.371L.412 8.056c-.533-.505-.55-1.4-.04-1.883a1.327 1.327 0 0 1 1.883-.04L12 15.473l9.744-9.333a1.327 1.327 0 0 1 1.884.04c.51.476.494 1.37-.04 1.876Z"
      fill="currentColor"
    />
  </IconBase>
);
