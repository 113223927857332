import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const EyeOpenIcon = (props: IconProps) => {
  const clipId = useId();

  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`} fill="currentColor">
        <path d="M11.998 4.88c-2.717 0-4.95 1.233-6.662 2.82-1.604 1.492-2.713 3.263-3.28 4.513.567 1.25 1.676 3.02 3.276 4.512 1.716 1.587 3.95 2.82 6.666 2.82s4.95-1.233 6.662-2.82c1.604-1.492 2.712-3.263 3.279-4.513-.567-1.25-1.675-3.02-3.275-4.512-1.717-1.587-3.95-2.82-6.666-2.82ZM3.973 6.238C5.936 4.413 8.631 2.88 11.998 2.88c3.366 0 6.062 1.533 8.024 3.358 1.95 1.812 3.254 3.975 3.875 5.462.137.33.137.696 0 1.025-.621 1.487-1.925 3.654-3.875 5.462-1.962 1.825-4.658 3.358-8.024 3.358-3.367 0-6.062-1.533-8.025-3.358-1.95-1.808-3.254-3.975-3.87-5.462a1.325 1.325 0 0 1 0-1.025c.616-1.487 1.92-3.654 3.87-5.462Z" />
        <path d="M12 8.877a3.339 3.339 0 0 1 3.335 3.335A3.339 3.339 0 0 1 12 15.549a3.339 3.339 0 0 1-3.335-3.335A3.339 3.339 0 0 1 12 8.877Zm0-2a5.335 5.335 0 1 0 0 10.67 5.335 5.335 0 0 0 0-10.67Z" />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
