import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const PetsNoIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M11.448 3.654a2.15 2.15 0 0 0-.646-1.533 2.224 2.224 0 0 0-1.56-.635 2.22 2.22 0 0 0-1.558.635 2.15 2.15 0 0 0-.646 1.533c0 .575.232 1.127.646 1.534a2.23 2.23 0 0 0 3.119 0 2.15 2.15 0 0 0 .645-1.534Zm-.032 6.6c.326-.51.891-.817 1.502-.817s1.18.312 1.502.818l2.26 3.55c.4.633.96 1.031 1.48 1.27a2.353 2.353 0 0 1 1.378 2.128c0 1.297-1.07 2.35-2.389 2.35-.51 0-.974-.154-1.36-.416-.9-.614-2.012-.795-2.866-.795-.855 0-1.967.18-2.867.795-.386.262-.85.416-1.36.416-1.318 0-2.389-1.053-2.389-2.35 0-.94.56-1.753 1.378-2.128a3.477 3.477 0 0 0 1.48-1.27l2.26-3.55h-.01Zm-4.13 2.404a1.29 1.29 0 0 1-.542.457c-1.562.723-2.646 2.281-2.646 4.093 0 2.494 2.058 4.518 4.594 4.518.973 0 1.874-.299 2.614-.804.799-.543 2.425-.543 3.22 0a4.64 4.64 0 0 0 2.619.804c2.535 0 4.594-2.024 4.594-4.518 0-1.812-1.085-3.37-2.646-4.093a1.236 1.236 0 0 1-.543-.457l-2.255-3.55a4.003 4.003 0 0 0-3.377-1.84 3.99 3.99 0 0 0-3.372 1.84l-2.26 3.55Zm9.307-6.835a2.23 2.23 0 0 0 1.56-.635 2.15 2.15 0 0 0 .646-1.534 2.15 2.15 0 0 0-.646-1.533 2.224 2.224 0 0 0-1.56-.635c-.584 0-1.145.228-1.559.635a2.15 2.15 0 0 0-.646 1.533c0 .575.232 1.127.646 1.534a2.23 2.23 0 0 0 1.56.635Zm6.616 2.891a2.138 2.138 0 0 0-.646-1.533 2.209 2.209 0 0 0-1.56-.635 2.238 2.238 0 0 0-1.559.635 2.138 2.138 0 0 0 0 3.067 2.209 2.209 0 0 0 1.56.635 2.237 2.237 0 0 0 1.559-.635 2.166 2.166 0 0 0 .646-1.534ZM4.833 10.883c.584 0 1.145-.229 1.559-.635a2.15 2.15 0 0 0 .646-1.534 2.15 2.15 0 0 0-.646-1.533 2.224 2.224 0 0 0-1.56-.635c-.584 0-1.145.228-1.559.635a2.15 2.15 0 0 0-.646 1.533c0 .575.233 1.127.646 1.534a2.23 2.23 0 0 0 1.56.635Z"
      fill="currentColor"
    />
    <path
      d="M22.497 3.786 2.346 22.623"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="2.64"
    />
  </IconBase>
);
