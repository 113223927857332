import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const ChevronUpIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="m.412 15.947 10.64-10.194c.305-.3.64-.473.973-.473.334 0 .666.124.924.372l10.639 10.194c.534.51.551 1.355.04 1.887a1.33 1.33 0 0 1-1.888.04l-9.715-9.258-9.765 9.352a1.33 1.33 0 0 1-1.888-.04 1.324 1.324 0 0 1 .04-1.88Z"
      fill="currentColor"
    />
  </IconBase>
);
