import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const InstagramIcon = (props: IconProps) => {
  const clipId = useId();

  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M12 9.143a2.857 2.857 0 1 0 .002 5.714A2.857 2.857 0 0 0 12 9.143Zm6.68-2.197a2.893 2.893 0 0 0-1.628-1.629c-1.125-.444-3.804-.345-5.052-.345-1.248 0-3.924-.103-5.052.345a2.893 2.893 0 0 0-1.63 1.63c-.443 1.124-.344 3.805-.344 5.052 0 1.248-.099 3.926.347 5.054a2.893 2.893 0 0 0 1.629 1.63c1.125.444 3.803.344 5.052.344 1.249 0 3.924.103 5.052-.345a2.893 2.893 0 0 0 1.629-1.629c.447-1.125.345-3.806.345-5.053 0-1.247.102-3.925-.345-5.053h-.002ZM12 16.393a4.393 4.393 0 1 1 0-8.786 4.393 4.393 0 0 1 0 8.786Zm4.574-7.945a1.025 1.025 0 1 1 .727-.299 1.024 1.024 0 0 1-.725.301l-.002-.002ZM21.429 0H2.57A2.571 2.571 0 0 0 0 2.571V21.43A2.571 2.571 0 0 0 2.571 24H21.43A2.57 2.57 0 0 0 24 21.429V2.57A2.571 2.571 0 0 0 21.429 0Zm-.918 15.536c-.069 1.373-.382 2.59-1.384 3.589-1.003 1-2.218 1.32-3.59 1.385-1.415.08-5.656.08-7.071 0-1.373-.07-2.585-.383-3.59-1.385-1.003-1.002-1.319-2.219-1.384-3.59-.08-1.415-.08-5.657 0-7.07.069-1.374.379-2.59 1.385-3.59s2.221-1.316 3.589-1.381c1.415-.08 5.657-.08 7.071 0 1.373.069 2.59.383 3.59 1.385s1.32 2.219 1.384 3.592c.08 1.41.08 5.648 0 7.065Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
