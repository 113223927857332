import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const PinterestIcon = (props: IconProps) => {
  const clipId = useId();

  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M24 2.571V21.43A2.572 2.572 0 0 1 21.429 24H8.27c.525-.879 1.2-2.143 1.468-3.177l.82-3.128c.429.82 1.682 1.51 3.016 1.51 3.97 0 6.825-3.648 6.825-8.18 0-4.345-3.546-7.596-8.11-7.596-5.68 0-8.69 3.808-8.69 7.96 0 1.929 1.029 4.329 2.668 5.095.252.118.38.064.44-.177.042-.182.267-1.077.363-1.49a.388.388 0 0 0-.09-.374c-.542-.66-.981-1.87-.981-3 0-2.904 2.196-5.71 5.941-5.71 3.23 0 5.496 2.2 5.496 5.35 0 3.558-1.794 6.022-4.135 6.022-1.291 0-2.256-1.066-1.95-2.378.37-1.565 1.087-3.252 1.087-4.382 0-2.84-4.044-2.449-4.044 1.339 0 1.162.39 1.955.39 1.955-1.681 7.115-1.933 7.206-1.585 10.318l.118.043H2.57A2.572 2.572 0 0 1 0 21.429V2.57A2.572 2.572 0 0 1 2.571 0H21.43A2.572 2.572 0 0 1 24 2.571Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
