import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const BellIcon = (props: IconProps) => (
  <IconBase {...props} viewBox="0 0 20 20">
    <path
      d="M10 0c.69 0 1.25.559 1.25 1.25v.7a5.94 5.94 0 0 1 5 5.862v1.305c0 1.774.605 3.496 1.71 4.883l.582.727a.935.935 0 0 1-.73 1.523H2.187a.938.938 0 0 1-.73-1.523l.581-.73a7.82 7.82 0 0 0 1.711-4.88V7.812a5.94 5.94 0 0 1 5-5.863V1.25C8.75.559 9.308 0 10 0Zm.312 3.75h-.625a4.064 4.064 0 0 0-4.063 4.063v1.304a9.701 9.701 0 0 1-1.55 5.258h11.85a9.702 9.702 0 0 1-1.55-5.258V7.812a4.064 4.064 0 0 0-4.062-4.062Zm2.187 13.75h-5c0 .664.262 1.3.73 1.77A2.5 2.5 0 0 0 10 20a2.5 2.5 0 0 0 1.77-.73 2.5 2.5 0 0 0 .73-1.77Z"
      fill="currentColor"
    />
  </IconBase>
);
