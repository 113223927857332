import { useId } from 'react';

import { type IconProps } from 'types/Icons';

export const WarningIcon = ({ size, ...props }: IconProps) => {
  const clipId = useId();

  return (
    <svg
      fill="currentColor"
      height={size ?? 16}
      viewBox="0 0 16 16"
      width={size ?? 16}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M8 1.5a6.5 6.5 0 1 1 0 13 6.5 6.5 0 0 1 0-13ZM8 16A8 8 0 1 0 8-.001 8 8 0 0 0 8 16ZM8 4a.748.748 0 0 0-.75.75v3.5c0 .416.334.75.75.75s.75-.334.75-.75v-3.5A.748.748 0 0 0 8 4Zm1 7a1 1 0 1 0-2 0 1 1 0 0 0 2 0Z"
          fill="#093F45"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h16v16H0z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};
