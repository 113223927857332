import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const CloseIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M20.17 18.433a1.293 1.293 0 0 1-1.828 1.829l-6.364-6.412-6.41 6.41a1.293 1.293 0 0 1-1.828-1.83l6.412-6.407-6.414-6.458a1.293 1.293 0 0 1 1.828-1.828l6.411 6.46 6.41-6.409a1.293 1.293 0 0 1 1.828 1.828l-6.412 6.407 6.366 6.41Z"
      fill="currentColor"
    />
  </IconBase>
);
