import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const HamburgerIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M0 3.20576c0-.70982.575893-1.28572 1.28571-1.28572H22.7143c.7125 0 1.2857.5759 1.2857 1.28572 0 .7125-.5732 1.28571-1.2857 1.28571H1.28571C.575893 4.49147 0 3.91826 0 3.20576Zm0 8.57144c0-.7125.575893-1.2857 1.28571-1.2857H22.7143c.7125 0 1.2857.5732 1.2857 1.2857s-.5732 1.2857-1.2857 1.2857H1.28571C.575893 13.0629 0 12.4897 0 11.7772Zm22.7143 9.8571H1.28571C.575893 21.6343 0 21.0611 0 20.3486s.575893-1.2857 1.28571-1.2857H22.7143c.7125 0 1.2857.5732 1.2857 1.2857s-.5732 1.2857-1.2857 1.2857Z"
      fill="currentColor"
    />
  </IconBase>
);
