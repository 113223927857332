import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const CancelIcon = (props: IconProps) => (
  <IconBase {...props}>
    <g clipPath="url(#clip0_3781_658)" id="Cancel">
      <path
        d="M18.0516 19.6453L4.35469 5.94844C3.0375 7.60781 2.25 9.7125 2.25 12C2.25 17.3859 6.61406 21.75 12 21.75C14.2875 21.75 16.3922 20.9625 18.0516 19.6453ZM19.6406 18.0563C20.9625 16.3922 21.75 14.2875 21.75 12C21.75 6.61406 17.3859 2.25 12 2.25C9.7125 2.25 7.60781 3.0375 5.94844 4.35469L19.6453 18.0516L19.6406 18.0563ZM0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472C5.76516 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12Z"
        fill="currentColor"
        id="Regular"
      />
    </g>
    <defs>
      <clipPath id="clip0_3781_658">
        <rect fill="white" height="24" width="24" />
      </clipPath>
    </defs>
  </IconBase>
);
