import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const DollarCircleIcon = (props: IconProps) => {
  const clipId = useId();

  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M21.75 12a9.75 9.75 0 1 0-19.5 0 9.75 9.75 0 0 0 19.5 0ZM0 12a12 12 0 1 1 24 0 12 12 0 0 1-24 0Zm12.975-5.737v.665c.455.056.91.183 1.36.31.088.023.173.046.262.074a1.014 1.014 0 0 1-.54 1.955c-.074-.019-.145-.042-.22-.06a12.194 12.194 0 0 0-.989-.25c-.618-.116-1.336-.06-1.912.188-.516.225-.942.77-.356 1.144.46.295 1.022.445 1.556.59.112.029.22.062.328.09.731.206 1.664.473 2.363.951.909.624 1.336 1.636 1.134 2.724-.192 1.05-.924 1.739-1.8 2.095-.366.15-.764.244-1.181.29v.713c0 .558-.455 1.013-1.013 1.013a1.014 1.014 0 0 1-1.012-1.013v-.815c-.68-.155-1.346-.37-2.007-.586a1.013 1.013 0 0 1 .638-1.922c.117.037.234.08.352.117.53.178 1.073.36 1.617.45.797.117 1.434.047 1.851-.122.563-.225.83-.895.277-1.27-.474-.324-1.06-.483-1.617-.633a8.063 8.063 0 0 1-.32-.09c-.707-.2-1.593-.45-2.258-.876-.915-.586-1.379-1.56-1.182-2.643.188-1.022.985-1.702 1.828-2.068.258-.112.535-.201.82-.267v-.755c0-.557.455-1.012 1.013-1.012.558 0 1.013.455 1.013 1.013h-.005Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
