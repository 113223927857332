import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const HelpIcon = (props: IconProps) => {
  const clipId = useId();

  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M18.0516 19.6453C16.3922 20.9625 14.2875 21.75 12 21.75c-2.2875 0-4.39219-.7875-6.05156-2.1047l3.76875-3.7687c.67031.3937 1.45311.6234 2.28751.6234.8344 0 1.6172-.225 2.2875-.6234l3.7687 3.7687h-.0093Zm3.1922.0094C22.9641 17.5734 24 14.9109 24 12c0-2.91094-1.0359-5.57344-2.7562-7.65469l.5531-.54843c.4406-.44063.4406-1.15313 0-1.58907s-1.1531-.44062-1.5891 0l-.5531.54844C17.5734 1.03594 14.9109 0 12 0 9.08906 0 6.42656 1.03594 4.34531 2.75625l-.54843-.55313c-.44063-.44062-1.15313-.44062-1.58907 0-.43594.44063-.44062 1.15313 0 1.58907l.54844.55312C1.03594 6.42656 0 9.08906 0 12c0 2.9109 1.03594 5.5734 2.75625 7.6547l-.55313.5484c-.44062.4406-.44062 1.1531 0 1.5891.44063.4359 1.15313.4406 1.58907 0l.55312-.5531C6.42656 22.9641 9.08906 24 12 24c2.9109 0 5.5734-1.0359 7.6547-2.7562l.5484.5531c.4406.4406 1.1531.4406 1.5891 0 .4359-.4406.4406-1.1531 0-1.5891l-.5531-.5531h.0047Zm-1.5985-1.5984-3.7687-3.7688c.3937-.6703.6234-1.4531.6234-2.2875s-.225-1.6172-.6234-2.2875l3.7687-3.76875C20.9625 7.60781 21.75 9.7125 21.75 12c0 2.2875-.7875 4.3922-2.1047 6.0516v.0047ZM18.0516 4.35469l-3.7688 3.76875C13.6125 7.72969 12.8297 7.5 11.9953 7.5s-1.6172.225-2.28749.62344L5.94844 4.35469C7.60781 3.0375 9.7125 2.25 12 2.25s4.3922.7875 6.0516 2.10469ZM8.12344 14.2875l-3.76875 3.7641C3.0375 16.3922 2.25 14.2875 2.25 12c0-2.2875.7875-4.39219 2.10469-6.05156l3.76875 3.76875C7.72969 10.3875 7.5 11.1703 7.5 12.0047c0 .8344.225 1.6172.62344 2.2875v-.0047ZM9.75 12c0-.5967.23705-1.169.659-1.591.422-.42195.9943-.659 1.591-.659s1.169.23705 1.591.659c.4219.422.659.9943.659 1.591s-.2371 1.169-.659 1.591c-.422.4219-.9943.659-1.591.659s-1.169-.2371-1.591-.659c-.42195-.422-.659-.9943-.659-1.591Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
