import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const BedMoreIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      clipRule="evenodd"
      d="M12 22.08c5.567 0 10.08-4.513 10.08-10.08S17.567 1.92 12 1.92 1.92 6.433 1.92 12 6.433 22.08 12 22.08ZM12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M8.743 16.603a5.45 5.45 0 0 1-1.348-.175 4.366 4.366 0 0 1-1.26-.536.805.805 0 0 1-.325-.35.785.785 0 0 1-.038-.411.713.713 0 0 1 .188-.35c.1-.1.216-.158.35-.175a.773.773 0 0 1 .46.113c.309.174.62.308.937.4a3.62 3.62 0 0 0 1.01.136c.35 0 .65-.062.9-.187.249-.125.44-.3.573-.524.133-.225.2-.487.2-.786 0-.466-.141-.84-.424-1.123-.283-.292-.67-.437-1.16-.437a2.23 2.23 0 0 0-.775.137 2.1 2.1 0 0 0-.736.45 1.71 1.71 0 0 1-.287.199.826.826 0 0 1-.374.087c-.208 0-.366-.054-.475-.162-.108-.108-.162-.258-.162-.45V8.38c0-.225.058-.395.175-.512.125-.125.3-.187.524-.187h4.106c.225 0 .395.058.512.175.124.108.187.27.187.486 0 .208-.063.37-.187.487-.117.117-.287.175-.512.175H7.557v2.795h-.412c.217-.29.504-.515.861-.674a2.87 2.87 0 0 1 1.174-.237c.557 0 1.04.121 1.447.362.416.233.736.562.961.986.233.416.35.903.35 1.46 0 .574-.13 1.082-.387 1.523a2.703 2.703 0 0 1-1.111 1.023c-.474.242-1.04.362-1.697.362Zm7.125-1.673c-.183 0-.325-.05-.425-.15a.62.62 0 0 1-.15-.437v-2.046h-2.008c-.175 0-.313-.046-.412-.138a.557.557 0 0 1-.15-.412c0-.174.05-.307.15-.399.1-.1.237-.15.412-.15h2.009V9.214c0-.183.05-.324.15-.424.1-.1.245-.15.436-.15.183 0 .32.05.412.15.1.1.15.241.15.424v1.984h2.01c.182 0 .32.05.411.15.1.092.15.225.15.4 0 .174-.05.312-.15.411-.091.092-.229.138-.412.138h-2.01v2.046a.618.618 0 0 1-.149.437c-.091.1-.233.15-.424.15Z"
      fill="currentColor"
    />
  </IconBase>
);
