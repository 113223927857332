import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const PetsYesIcon = (props: IconProps) => {
  const clipId = useId();

  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M10.423 3.345c0-.633-.25-1.24-.693-1.686a2.356 2.356 0 0 0-3.346 0 2.395 2.395 0 0 0 0 3.372 2.356 2.356 0 0 0 3.346 0 2.395 2.395 0 0 0 .693-1.686Zm-.035 7.26c.35-.562.957-.9 1.612-.9.655 0 1.267.343 1.612.9l2.425 3.905a3.701 3.701 0 0 0 1.587 1.396 2.591 2.591 0 0 1 1.478 2.34 2.575 2.575 0 0 1-2.563 2.584 2.533 2.533 0 0 1-1.459-.457c-.966-.676-2.158-.875-3.075-.875-.917 0-2.11.2-3.076.875a2.533 2.533 0 0 1-1.458.457 2.575 2.575 0 0 1-2.563-2.584 2.58 2.58 0 0 1 1.478-2.34 3.757 3.757 0 0 0 1.587-1.396l2.425-3.906h-.01Zm-4.43 2.643c-.138.223-.35.387-.582.502a4.976 4.976 0 0 0-2.839 4.501c0 2.743 2.208 4.969 4.929 4.969 1.045 0 2.01-.328 2.804-.884.858-.597 2.602-.597 3.455 0a4.893 4.893 0 0 0 2.81.884c2.72 0 4.928-2.226 4.928-4.969a4.976 4.976 0 0 0-2.839-4.502 1.331 1.331 0 0 1-.582-.501l-2.42-3.906A4.27 4.27 0 0 0 12 7.32a4.255 4.255 0 0 0-3.618 2.022l-2.424 3.906Zm9.985-7.518c.627 0 1.229-.251 1.673-.699a2.395 2.395 0 0 0 0-3.372 2.356 2.356 0 0 0-3.346 0 2.395 2.395 0 0 0 0 3.372 2.356 2.356 0 0 0 1.673.699Zm7.097 3.18a2.402 2.402 0 0 0-.693-1.686 2.365 2.365 0 0 0-1.673-.699 2.349 2.349 0 0 0-1.672.699 2.386 2.386 0 0 0-.693 1.686A2.403 2.403 0 0 0 19 10.596a2.361 2.361 0 0 0 1.673.699 2.348 2.348 0 0 0 1.673-.699 2.387 2.387 0 0 0 .693-1.686ZM3.326 11.295c.627 0 1.229-.251 1.673-.699a2.395 2.395 0 0 0 0-3.372 2.356 2.356 0 0 0-3.346 0 2.395 2.395 0 0 0 0 3.372 2.356 2.356 0 0 0 1.673.699Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
