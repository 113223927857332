import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const LockIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M8.4 6.24v2.88h7.2V6.24c0-1.989-1.611-3.6-3.6-3.6a3.599 3.599 0 0 0-3.6 3.6ZM6.24 9.12V6.24a5.76 5.76 0 1 1 11.52 0v2.88h1.44A2.883 2.883 0 0 1 22.08 12v8.64a2.883 2.883 0 0 1-2.88 2.88H4.8a2.883 2.883 0 0 1-2.88-2.88V12A2.883 2.883 0 0 1 4.8 9.12h1.44ZM4.08 12v8.64c0 .396.324.72.72.72h14.4c.396 0 .72-.324.72-.72V12a.722.722 0 0 0-.72-.72H4.8a.722.722 0 0 0-.72.72Z"
      fill="currentColor"
    />
  </IconBase>
);
