import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const TwitterIcon = (props: IconProps) => {
  const clipId = useId();

  return (
    <IconBase viewBox="0 0 12 12" {...props}>
      <g clipPath={`url(#${clipId})`} id="X">
        <path
          d="M1.71429 0C0.76875 0 0 0.76875 0 1.71429V10.2857C0 11.2313 0.76875 12 1.71429 12H10.2857C11.2313 12 12 11.2313 12 10.2857V1.71429C12 0.76875 11.2313 0 10.2857 0H1.71429ZM9.67232 2.25L6.89196 5.42679L10.1625 9.75H7.60179L5.59821 7.12768L3.30268 9.75H2.03036L5.00357 6.35089L1.86696 2.25H4.49196L6.30536 4.64732L8.4 2.25H9.67232ZM8.65982 8.98929L4.10893 2.97054H3.35089L7.95268 8.98929H8.65714H8.65982Z"
          fill="#093F45"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <rect fill="white" height="12" width="12" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
