import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const SuitcaseIcon = (props: IconProps) => {
  const clipId = useId();
  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M8.25 3.585V5.46h7.5V3.585a.376.376 0 0 0-.375-.375h-6.75a.376.376 0 0 0-.375.375ZM6 5.46V3.585A2.627 2.627 0 0 1 8.625.96h6.75A2.627 2.627 0 0 1 18 3.585V5.46h3c1.655 0 3 1.345 3 3v12c0 1.655-1.345 3-3 3H3c-1.655 0-3-1.345-3-3v-12c0-1.655 1.345-3 3-3h3Zm10.875 2.25H6.75v13.5h10.5V7.71h-.375ZM3 7.71a.752.752 0 0 0-.75.75v12c0 .412.337.75.75.75h1.5V7.71H3Zm18 13.5c.413 0 .75-.338.75-.75v-12a.752.752 0 0 0-.75-.75h-1.5v13.5H21Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
