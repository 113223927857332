import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const BellConciergeIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M10.125 2.88C9.502 2.88 9 3.382 9 4.005s.502 1.125 1.125 1.125h.75v1.56C5.606 7.25 1.5 11.712 1.5 17.13h2.25a8.25 8.25 0 0 1 16.5 0h2.25c0-5.419-4.106-9.881-9.375-10.44V5.13h.75c.623 0 1.125-.502 1.125-1.125s-.502-1.125-1.125-1.125h-3.75Zm-9 15.75C.502 18.63 0 19.132 0 19.755s.502 1.125 1.125 1.125h21.75c.623 0 1.125-.502 1.125-1.125s-.502-1.125-1.125-1.125H1.125Z"
      fill="currentColor"
    />
  </IconBase>
);
