import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const EmailIcon = (props: IconProps) => {
  return (
    <IconBase {...props} viewBox="0 0 12 12">
      <path
        d="M1.125 1.5C0.503906 1.5 0 2.00391 0 2.625C0 2.97891 0.166406 3.31172 0.45 3.525L5.55 7.35C5.81719 7.54922 6.18281 7.54922 6.45 7.35L11.55 3.525C11.8336 3.31172 12 2.97891 12 2.625C12 2.00391 11.4961 1.5 10.875 1.5H1.125ZM0 4.125V9C0 9.82734 0.672656 10.5 1.5 10.5H10.5C11.3273 10.5 12 9.82734 12 9V4.125L6.9 7.95C6.36562 8.35078 5.63438 8.35078 5.1 7.95L0 4.125Z"
        fill="currentColor"
      />
    </IconBase>
  );
};
