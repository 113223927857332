import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const OccupancyIcon = (props: IconProps) => {
  const clipId = useId();

  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M12.567.155a1.126 1.126 0 0 0-1.139 0L.553 6.53A1.127 1.127 0 0 0 0 7.5v15.375C0 23.498.502 24 1.125 24s1.125-.502 1.125-1.125V8.147L12 2.428l9.75 5.719v14.728c0 .623.502 1.125 1.125 1.125S24 23.498 24 22.875V7.5c0-.398-.21-.769-.558-.97L12.567.155ZM12 9.75A1.875 1.875 0 1 0 12 6a1.875 1.875 0 0 0 0 3.75Zm-.75 3.023a1.98 1.98 0 0 1 .29-.023h.915c.098 0 .197.01.29.023v4.477H11.25v-4.477Zm0 6.727h1.5v3.375c0 .623.502 1.125 1.125 1.125S15 23.498 15 22.875v-7.486l.886 1.64a1.126 1.126 0 0 0 1.983-1.063l-1.777-3.296a4.124 4.124 0 0 0-3.633-2.17h-.918a4.13 4.13 0 0 0-3.633 2.17L6.13 15.966a1.123 1.123 0 0 0 .46 1.523 1.123 1.123 0 0 0 1.523-.46L9 15.39v7.486C9 23.498 9.502 24 10.125 24s1.125-.502 1.125-1.125V19.5Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
