import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const CalendarIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M19.811 2.623h-1.765V1.12c0-.615-.505-1.12-1.121-1.12h-.048c-.616 0-1.121.505-1.121 1.121v1.502H8.502V1.12C8.502.505 7.997 0 7.38 0h-.047C6.716 0 6.21.505 6.21 1.121v1.502H4.446A3.015 3.015 0 0 0 1.44 5.629v15.365A3.015 3.015 0 0 0 4.446 24h15.365a3.015 3.015 0 0 0 3.006-3.006V5.629a3.015 3.015 0 0 0-3.006-3.006ZM4.494 4.913h15.27c.42 0 .763.344.763.763v1.527H3.73V5.676c0-.42.344-.763.764-.763Zm15.27 16.797H4.494a.766.766 0 0 1-.764-.764V9.494h16.797v11.452c0 .42-.344.764-.764.764Z"
      fill="currentColor"
    />
  </IconBase>
);
