import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const EllipsesIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M20 12.08c0-1.106.894-2 2-2 1.106 0 2 .894 2 2 0 1.106-.894 2-2 2-1.106 0-2-.894-2-2Zm-10 0c0-1.106.894-2 2-2 1.106 0 2 .894 2 2 0 1.106-.894 2-2 2-1.106 0-2-.894-2-2Zm-6 0a2 2 0 1 1-4 0 1.999 1.999 0 1 1 4 0Z"
      fill="currentColor"
    />
  </IconBase>
);
