import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const UserIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M15.69 6a3.75 3.75 0 1 0-7.5 0 3.75 3.75 0 0 0 7.5 0ZM5.94 6a6 6 0 1 1 12 0 6 6 0 0 1-12 0ZM3.75 21.75h16.38a6.11 6.11 0 0 0-6.048-5.25H9.798a6.11 6.11 0 0 0-6.047 5.25Zm-2.31.858a8.356 8.356 0 0 1 8.358-8.358h4.284a8.356 8.356 0 0 1 8.358 8.358c0 .769-.623 1.392-1.392 1.392H2.832a1.392 1.392 0 0 1-1.392-1.392Z"
      fill="currentColor"
    />
  </IconBase>
);
