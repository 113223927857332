import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const CreditCardIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M4 16.333c0-.554.446-1 1-1h2c.554 0 1 .446 1 1 0 .555-.446 1-1 1H5c-.554 0-1-.445-1-1Zm5.333 0c0-.554.446-1 1-1H15c.554 0 1 .446 1 1 0 .555-.446 1-1 1h-4.667c-.554 0-1-.445-1-1Z"
      fill="currentColor"
    />
    <path
      clipRule="evenodd"
      d="M0 5.333a2.67 2.67 0 0 1 2.667-2.666h18.666A2.67 2.67 0 0 1 24 5.333v13.334a2.67 2.67 0 0 1-2.667 2.666H2.667A2.67 2.67 0 0 1 0 18.667V5.333Zm22 0c0-.366-.3-.666-.667-.666H2.667c-.367 0-.667.3-.667.666v1.334h20V5.333Zm0 13.334v-6.4H2v6.4c0 .366.3.666.667.666h18.666c.367 0 .667-.3.667-.666Zm.001-10.061H2.003v1.74H22v-1.74Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </IconBase>
);
