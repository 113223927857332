import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const SupportIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M11.94 1.5A7.498 7.498 0 0 0 4.44 9v.75c0 .412-.338.75-.75.75a.752.752 0 0 1-.75-.75V9c0-4.969 4.031-9 9-9s9 4.031 9 9v.75A5.247 5.247 0 0 1 15.69 15h-4.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h1.5c.83 0 1.5.67 1.5 1.5h1.5a3.749 3.749 0 0 0 3.75-3.75V9c0-4.144-3.356-7.5-7.5-7.5Zm0 1.5a6 6 0 0 1 5.705 7.866A2.25 2.25 0 0 1 15.69 12h-.403a2.923 2.923 0 0 0-.464-.605A3.749 3.749 0 0 0 11.94 5.25 3.749 3.749 0 0 0 8.19 9c0 .91.323 1.744.863 2.395a2.98 2.98 0 0 0-.858 2.293A6 6 0 0 1 11.94 3Zm-.75 13.5h4.95c3.478 0 6.3 2.822 6.3 6.3 0 .66-.54 1.2-1.2 1.2H2.64c-.66 0-1.2-.54-1.2-1.2 0-3.478 2.822-6.3 6.3-6.3h3.45Zm4.95 2.25h-8.4a4.051 4.051 0 0 0-3.914 3h16.228a4.05 4.05 0 0 0-3.914-3Z"
      fill="currentColor"
    />
  </IconBase>
);
