import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const BedTwinIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M18 18V7.2c.672 0 1.2-.528 1.2-1.2V1.2C19.2.528 18.672 0 18 0H6c-.672 0-1.2.528-1.2 1.2V6c0 .672.528 1.2 1.2 1.2V18c-.672 0-1.2.528-1.2 1.2v2.4c0 .672.528 1.2 1.2 1.2h1.2c0 .672.528 1.2 1.2 1.2.672 0 1.2-.528 1.2-1.2h4.8c0 .672.528 1.2 1.2 1.2.672 0 1.2-.528 1.2-1.2H18c.672 0 1.2-.528 1.2-1.2v-2.4c0-.672-.528-1.2-1.2-1.2Zm-2.4-8.4h1.2V18H7.2V9.6h8.4ZM8.4 7.2V4.224c0-.336.24-.576.576-.576h6c.336 0 .576.288.576.576V7.2H8.4ZM6 1.824c0-.336.192-.576.432-.576h11.136c.24 0 .432.288.432.576v3.6c0 .336-.24.576-.576.576H16.8V3.6c0-.672-.528-1.2-1.2-1.2H8.4c-.672 0-1.2.528-1.2 1.2V6h-.576a.555.555 0 0 1-.576-.576v-3.6H6Zm12 19.488a.31.31 0 0 1-.288.288H6.288c-.192 0-.288-.144-.288-.288v-1.824a.31.31 0 0 1 .288-.288h11.424c.192 0 .288.144.288.288v1.824Z"
      fill="currentColor"
    />
  </IconBase>
);
