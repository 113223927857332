import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const BedDoubleFullIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M11.334 8.341V5.674a.667.667 0 0 0-.666-.666H.667A.666.666 0 0 0 0 5.674v2.667c0 .368.299.667.667.667v6a.667.667 0 0 0-.667.667v1.334c0 .368.299.666.667.666h.666a.667.667 0 0 0 1.334 0h6a.667.667 0 0 0 1.334 0h.667a.667.667 0 0 0 .666-.666v-1.334a.667.667 0 0 0-.666-.667v-6a.667.667 0 0 0 .666-.667ZM.667 6.008c0-.187.106-.334.24-.334h9.52c.134 0 .24.147.24.334v2a.333.333 0 0 1-.333.333H10V7.008a.667.667 0 0 0-.666-.667H2a.667.667 0 0 0-.667.667V8.34H1a.334.334 0 0 1-.333-.333v-2ZM9.334 7.34v1.333c0 .185-.15.334-.334.334H6.334A.334.334 0 0 1 6 8.674V7.341c0-.184.15-.334.334-.334H9c.185 0 .334.15.334.334Zm-4 0v1.333c0 .185-.15.334-.334.334H2.334A.334.334 0 0 1 2 8.674V7.341c0-.184.15-.334.334-.334H5c.183 0 .333.15.333.334Zm5.334 9.5a.166.166 0 0 1-.167.167H.833a.166.166 0 0 1-.166-.166v-1c0-.093.074-.167.166-.167h9.668c.092 0 .167.074.167.166v1Zm-9.335-1.833v-4c0-.368.299-.667.667-.667h7.334c.368 0 .667.299.667.667v4H1.333ZM24 8.334V5.667A.667.667 0 0 0 23.333 5h-10a.667.667 0 0 0-.667.667v2.667c0 .368.298.666.666.666v6a.667.667 0 0 0-.666.668V17c0 .368.298.667.666.667H14a.667.667 0 0 0 1.334 0h6a.667.667 0 0 0 1.334 0h.666A.667.667 0 0 0 24 17v-1.334a.667.667 0 0 0-.667-.666V9A.667.667 0 0 0 24 8.333ZM13.332 6c0-.186.107-.333.24-.333h9.521c.134 0 .24.147.24.333v2a.333.333 0 0 1-.333.334h-.333V7.001A.667.667 0 0 0 22 6.334h-7.334a.667.667 0 0 0-.667.667v1.333h-.333A.334.334 0 0 1 13.332 8V6ZM22 7.334v1.333c0 .185-.15.334-.333.334H19a.334.334 0 0 1-.334-.334V7.334c0-.185.15-.334.334-.334h2.667c.184 0 .333.15.333.334Zm-4 0v1.333c0 .185-.15.334-.334.334h-2.667a.334.334 0 0 1-.334-.334V7.334c0-.185.15-.334.334-.334h2.667c.184 0 .334.15.334.334Zm5.333 9.5a.166.166 0 0 1-.166.167h-9.668a.166.166 0 0 1-.167-.167v-1c0-.092.075-.166.167-.166h9.668c.092 0 .166.074.166.166v1ZM14 15.001v-4c0-.369.299-.667.667-.667H22c.368 0 .667.298.667.667v4h-8.668Z"
      fill="#093545"
    />
  </IconBase>
);
