import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const ChevronLeftIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M16.377 23.588 6.182 12.948c-.3-.355-.423-.69-.423-.973 0-.284.124-.667.372-.924L16.326.412a1.332 1.332 0 0 1 1.887-.04 1.33 1.33 0 0 1 .04 1.888l-9.306 9.715 9.352 9.764a1.33 1.33 0 0 1-.04 1.888 1.326 1.326 0 0 1-1.883-.04Z"
      fill="currentColor"
    />
  </IconBase>
);
