import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const ArrowLeftIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M.33 11.018a1.12 1.12 0 0 0 0 1.59l6.375 6.378a1.124 1.124 0 0 0 1.589-1.589L3.84 12.945l19.034-.005c.623 0 1.125-.502 1.125-1.125s-.502-1.125-1.125-1.125H3.841l4.453-4.452a1.124 1.124 0 0 0-1.589-1.589L.33 11.019Z"
      fill="currentColor"
    />
  </IconBase>
);
