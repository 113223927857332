import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const SearchIcon = (props: IconProps) => {
  const clipId = useId();

  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M17.248 9.75a7.499 7.499 0 1 0-14.998 0 7.499 7.499 0 1 0 14.998 0ZM15.8 17.393a9.702 9.702 0 0 1-6.051 2.104A9.748 9.748 0 0 1 0 9.75 9.748 9.748 0 0 1 9.75 0a9.748 9.748 0 0 1 9.748 9.75 9.702 9.702 0 0 1-2.104 6.05l6.276 6.276a1.124 1.124 0 0 1-1.589 1.589L15.8 17.394Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
