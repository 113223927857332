import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const HomeIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M12.6222 2.14983c-.36-.30638-.884-.30638-1.244 0L.818201 11.1798c-.404.3467-.452.9554-.112 1.3626.339999.4071.947999.4555 1.351999.1129l.984-.8385v7.5182c0 1.7819 1.432 3.225 3.2 3.225h11.52c1.768 0 3.2-1.4431 3.2-3.225v-7.5182l.98.8385c.404.3466 1.012.2942 1.352-.1129.34-.4072.292-1.0199-.112-1.3626l-10.56-9.02997ZM4.9622 19.335v-9.159l7.04-6.01861 7.04 6.01861v9.159c0 .7136-.572 1.29-1.28 1.29h-1.92v-6.1275c0-.8909-.716-1.6125-1.6-1.6125h-4.48c-.884 0-1.6.7216-1.6 1.6125v6.1275h-1.92c-.708 0-1.28-.5764-1.28-1.29Zm5.12 1.29V14.82h3.84v5.805h-3.84Z"
      fill="currentColor"
    />
  </IconBase>
);
