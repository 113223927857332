import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const StarHalfIcon = (props: IconProps) => {
  const clipId = useId();
  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`}>
        <path
          clipRule="evenodd"
          d="M12 .005a1.077 1.077 0 0 0-1.077.629L7.83 7.258.924 8.322a1.091 1.091 0 0 0-.87.76 1.16 1.16 0 0 0 .267 1.153l5.008 5.162-1.222 7.29c-.027.384.14.853.473 1.102.338.248.78.281 1.105.08L12 20.374V.005Z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
