import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const FutonIcon = (props: IconProps) => {
  const clipId = useId();

  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M23.4 8.4a.6.6 0 0 0-.6.6v2.652a1.2 1.2 0 0 0-.66-.24V6.6a1.2 1.2 0 0 0-1.2-1.2H3.036a1.2 1.2 0 0 0-1.2 1.2v4.812a1.2 1.2 0 0 0-.66.24V9a.6.6 0 0 0-.6-.6A.6.6 0 0 0 0 9v9a.6.6 0 0 0 1.2 0v-3h21.6v3a.6.6 0 0 0 1.2 0V9a.6.6 0 0 0-.6-.6ZM3.036 6.6h17.928v4.704H3.036V6.6Zm19.056 7.2H1.908v-1.2h20.184v1.2Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
