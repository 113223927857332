import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const CameraIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M9.333 1.44c-1.13 0-2.133.722-2.49 1.795L6.442 4.44H3c-1.655 0-3 1.345-3 3v12c0 1.655 1.345 3 3 3h18c1.655 0 3-1.345 3-3v-12c0-1.655-1.345-3-3-3h-3.44l-.404-1.205a2.62 2.62 0 0 0-2.489-1.795H9.333Zm-.356 2.508a.373.373 0 0 1 .356-.258h5.334c.16 0 .305.103.356.258l.657 1.973c.154.46.58.769 1.068.769H21c.413 0 .75.337.75.75v12c0 .412-.337.75-.75.75H3a.752.752 0 0 1-.75-.75v-12c0-.413.337-.75.75-.75h4.252c.482 0 .914-.31 1.068-.769l.657-1.973ZM12 18.69a5.251 5.251 0 1 0 0-10.502 5.251 5.251 0 0 0 0 10.502Zm-3-5.25a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z"
      fill="currentColor"
    />
  </IconBase>
);
