import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const SortDescendingIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="m.292 17.184 4.057 4.114a1 1 0 0 0 1.433 0l4.057-4.114a1.034 1.034 0 0 0 0-1.453 1.004 1.004 0 0 0-1.433 0l-2.324 2.357V3.428c0-.57-.452-1.028-1.014-1.028a1.02 1.02 0 0 0-1.015 1.028v14.66l-2.324-2.36a1 1 0 0 0-1.433 0 1.038 1.038 0 0 0 0 1.452l-.004.004Zm12.551 4.414h2.029a1.02 1.02 0 0 0 1.014-1.029c0-.57-.452-1.028-1.014-1.028h-2.029c-.562 0-1.014.459-1.014 1.028 0 .57.452 1.029 1.014 1.029Zm0-5.485h4.733a1.02 1.02 0 0 0 1.015-1.029 1.02 1.02 0 0 0-1.015-1.028h-4.733c-.562 0-1.014.458-1.014 1.028 0 .57.452 1.029 1.014 1.029Zm0-5.485h7.438a1.02 1.02 0 0 0 1.014-1.029c0-.57-.452-1.028-1.014-1.028h-7.438c-.562 0-1.014.458-1.014 1.028 0 .57.452 1.029 1.014 1.029Zm0-5.485h10.143A1.02 1.02 0 0 0 24 4.114c0-.57-.452-1.028-1.014-1.028H12.843c-.562 0-1.014.458-1.014 1.028 0 .57.452 1.029 1.014 1.029Z"
      fill="currentColor"
    />
  </IconBase>
);
