import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const EditIcon = (props: IconProps) => {
  const clipId = useId();

  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M19.148 1.877c.3 0 .583.118.796.33l1.847 1.852h.001v.002c.057.056.108.117.145.174a1.132 1.132 0 0 1-.144 1.418l-2.231 2.23-.274-.273-2.91-2.91-.26-.259 2.234-2.233a1.12 1.12 0 0 1 .797-.33m0-1.877c-.769 0-1.537.293-2.124.88L2.75 15.154a4.13 4.13 0 0 0-.334.376l-.014.018-.014.019a4.166 4.166 0 0 0-.68 1.342l-1.08 3.664-.581 1.98a1.126 1.126 0 0 0 1.393 1.403l1.985-.586 3.665-1.08a4.06 4.06 0 0 0 1.342-.68l.018-.014.02-.014c.065-.052.126-.103.187-.155.066-.056.127-.117.188-.178L23.12 6.979a3.004 3.004 0 0 0 0-4.246L21.271.88A2.993 2.993 0 0 0 19.148 0ZM9.25 17.646l-2.327-.582-.582-2.327 8.71-8.709 2.909 2.91-8.71 8.708Zm-6.476 3.566.756-2.571.324-1.098a1.72 1.72 0 0 1 .16-.38l.525-.347.361 1.454c.099.404.418.718.821.821l1.455.362-.347.525a1.917 1.917 0 0 1-.38.16l-1.098.324-2.577.75Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
