import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const SaveIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M2.57678 3.86851V21.0114C2.57678 21.4828 2.9625 21.8685 3.43393 21.8685H20.5768C21.0482 21.8685 21.4339 21.4828 21.4339 21.0114V7.85958C21.4339 7.63458 21.3429 7.41494 21.1821 7.25423L22.9982 5.43816C23.6411 6.08101 24 6.95423 24 7.86494V21.0114C24 22.9024 22.4625 24.4399 20.5714 24.4399H3.43393C1.54286 24.4399 0.00535583 22.9024 0.00535583 21.0114V3.86851C0.00535583 1.97744 1.54286 0.439941 3.43393 0.439941H16.5857C17.4964 0.439941 18.3696 0.79887 19.0125 1.44173L23.0036 5.4328L21.1875 7.24887L17.1911 3.26316C17.175 3.24708 17.1643 3.23637 17.1482 3.2203V8.5828C17.1482 9.2953 16.575 9.86851 15.8625 9.86851H5.57678C4.86428 9.86851 4.29107 9.2953 4.29107 8.5828V3.01137H3.43393C2.9625 3.01137 2.57678 3.39708 2.57678 3.86851ZM6.8625 3.01137V7.29708H14.5768V3.01137H6.8625ZM8.57678 15.8685C8.57678 14.9592 8.93801 14.0871 9.58099 13.4441C10.224 12.8012 11.096 12.4399 12.0054 12.4399C12.9147 12.4399 13.7867 12.8012 14.4297 13.4441C15.0727 14.0871 15.4339 14.9592 15.4339 15.8685C15.4339 16.7778 15.0727 17.6499 14.4297 18.2929C13.7867 18.9359 12.9147 19.2971 12.0054 19.2971C11.096 19.2971 10.224 18.9359 9.58099 18.2929C8.93801 17.6499 8.57678 16.7778 8.57678 15.8685Z"
      fill="currentColor"
    />
  </IconBase>
);
