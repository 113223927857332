import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const EyeClosedIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      clipRule="evenodd"
      d="M22.554 21.2a.895.895 0 0 0 1.258-.16.897.897 0 0 0-.16-1.259l-3.844-2.976c1.439-1.495 2.418-3.161 2.912-4.346a1.19 1.19 0 0 0 0-.92c-.557-1.334-1.727-3.274-3.476-4.9-1.76-1.637-4.179-3.013-7.199-3.013-2.548 0-4.669.98-6.326 2.27L1.446 2.588A.897.897 0 0 0 .348 4.007L4.36 7.113c-1.481 1.515-2.486 3.22-2.986 4.426a1.189 1.189 0 0 0 0 .92c.553 1.334 1.723 3.278 3.472 4.9 1.76 1.637 4.18 3.012 7.2 3.012 2.594 0 4.745-1.015 6.416-2.34l4.092 3.17Zm-4.174-5.5c1.247-1.259 2.119-2.675 2.584-3.701-.509-1.122-1.503-2.71-2.938-4.048-1.54-1.424-3.544-2.53-5.98-2.53-1.882 0-3.506.659-4.855 1.615l11.19 8.664ZM5.79 8.22l11.204 8.675c-1.368.992-3.022 1.682-4.948 1.682-2.437 0-4.44-1.106-5.98-2.53-1.435-1.338-2.43-2.927-2.938-4.048.475-1.047 1.373-2.502 2.662-3.78Zm6.657.814a3 3 0 0 1 2.372 1.836l1.997 1.547a4.786 4.786 0 0 0-6.367-4.93l1.998 1.547Zm-3.239 3.904a2.998 2.998 0 0 0 2.642 2.045l1.916 1.484a4.786 4.786 0 0 1-6.473-5.012l1.915 1.483Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </IconBase>
);
