import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const TagIcon = (props: IconProps) => {
  const clipId = useId();
  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M10.833 0c.932 0 1.827.368 2.485 1.026l9.653 9.656a3.51 3.51 0 0 1 0 4.965l-7.322 7.324a3.509 3.509 0 0 1-4.964 0l-9.654-9.656A3.483 3.483 0 0 1 0 10.835V2.633A2.634 2.634 0 0 1 2.633 0h8.2Zm-8.2 10.835c0 .231.093.456.258.62l9.653 9.656c.34.34.9.34 1.24 0l7.323-7.324a.88.88 0 0 0 0-1.24l-9.654-9.656a.881.881 0 0 0-.62-.258h-8.2v8.202Zm3.51-6.446a1.755 1.755 0 1 1 0 3.51 1.755 1.755 0 0 1 0-3.51Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
