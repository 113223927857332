import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const HighlightIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M7.15918 15.9482L5.57168 17.5357L7.71752 19.6816L9.30502 18.0941C9.68002 17.7191 10.1884 17.5066 10.7175 17.5066H12.7175L13.3925 16.5691L8.68418 11.8607L7.74668 12.5316V14.5316C7.74668 15.0607 7.53418 15.5691 7.15918 15.9441V15.9482ZM14.5759 14.9232L21.03 5.93155C21.0634 5.88572 21.08 5.83155 21.08 5.77738C21.08 5.70655 21.0509 5.63988 21.0009 5.58988L19.6634 4.25238C19.6134 4.20238 19.5467 4.17322 19.4759 4.17322C19.4217 4.17322 19.3675 4.18988 19.3217 4.22322L10.33 10.6774L14.5759 14.9232ZM12.7217 19.5066H10.7217L8.90085 21.3274C8.45502 21.7732 7.81752 21.9149 7.25502 21.7482L5.88835 23.1149C5.63835 23.3649 5.30085 23.5066 4.94668 23.5066H1.41335C0.67585 23.5066 0.0800171 22.9107 0.0800171 22.1732V21.9774C0.0800171 21.6232 0.221684 21.2857 0.471684 21.0357L3.50502 18.0024C3.33835 17.4357 3.48002 16.8024 3.92585 16.3566L5.74668 14.5316V12.5316C5.74668 11.8857 6.05502 11.2816 6.58002 10.9066L18.155 2.59822C18.5384 2.32322 19.0009 2.17322 19.4759 2.17322C20.0759 2.17322 20.6509 2.41072 21.0759 2.83572L22.4175 4.17738C22.8425 4.60238 23.08 5.17738 23.08 5.77738C23.08 6.25238 22.93 6.71488 22.655 7.09822L14.3467 18.6732C13.9717 19.1982 13.3675 19.5066 12.7217 19.5066ZM4.77585 19.5607L2.82585 21.5066H4.66335L5.69252 20.4774L4.77168 19.5566L4.77585 19.5607ZM10.4134 21.5066H23.08C23.6342 21.5066 24.08 21.9524 24.08 22.5066C24.08 23.0607 23.6342 23.5066 23.08 23.5066H10.4134C9.85918 23.5066 9.41335 23.0607 9.41335 22.5066C9.41335 21.9524 9.85918 21.5066 10.4134 21.5066Z"
      fill="currentColor"
    />
  </IconBase>
);
