import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { type PluginAPI } from 'tailwindcss/types/config';

// Helper to conditionally add Tailwind CSS classes
// Reference: https://ui.shadcn.com/docs/installation/manual
export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));

export const darkPlugin = ({ addVariant }: PluginAPI) => {
  addVariant('dark', [
    '&.dark:not(.theme-invert)',
    '&.theme-invert:not(.dark)',
    '.dark &:not(.theme-invert)',
    '.dark :not(.theme-invert) &',
    ':not(.dark) &.theme-invert',
    ':not(.dark) .theme-invert &',
  ]);
};

export const focusPlugin = ({ addVariant }: PluginAPI) => {
  addVariant('focus-visible-within', ['&:has(:focus-visible)']);
  addVariant('peer-error', [':merge(.peer):invalid:not(:focus) ~ &']);
  addVariant('peer-not-focus', [':merge(.peer):not(:focus) ~ &']);
};

export const inputPlugin = ({ addBase }: PluginAPI) => {
  addBase([
    {
      'input[type="number"]': {
        'appearance': 'textfield !important',
        '&::-webkit-outer-spin-button': {
          margin: '0',
          appearance: 'none',
        },
        '&::-webkit-inner-spin-button': {
          margin: '0',
          appearance: 'none',
        },
      },
    },
  ]);
};
