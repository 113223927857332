import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const CheckIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M23.59 4.215c.547.504.547 1.319 0 1.775L9.44 20.14c-.456.547-1.271.547-1.775 0L.377 12.85c-.503-.455-.503-1.27 0-1.774a1.294 1.294 0 0 1 1.819 0l6.38 6.384 13.24-13.245c.504-.5 1.318-.5 1.774 0Z"
      fill="currentColor"
    />
  </IconBase>
);
