import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const LogoutIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M23.67 12.736a1.12 1.12 0 0 0 0-1.59l-6-6.003a1.125 1.125 0 0 0-1.589 1.589l4.078 4.077-11.535.005c-.623 0-1.125.502-1.125 1.125s.502 1.125 1.125 1.125H20.16l-4.078 4.078a1.125 1.125 0 0 0 1.589 1.589l6-5.995ZM7.874 3.69C8.498 3.69 9 3.188 9 2.565 9 1.94 8.498 1.44 7.874 1.44h-3.75A4.125 4.125 0 0 0 0 5.565v12.748a4.125 4.125 0 0 0 4.125 4.125h3.75c.623 0 1.124-.502 1.124-1.125s-.501-1.125-1.125-1.125h-3.75a1.874 1.874 0 0 1-1.874-1.875V5.565c0-1.036.839-1.875 1.875-1.875h3.75Z"
      fill="currentColor"
    />
  </IconBase>
);
