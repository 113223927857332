import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const AlertIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M11.644 3.892A.413.413 0 0 1 12 3.69c.145 0 .281.075.356.202l9.296 15.267a.68.68 0 0 1-.581 1.031H2.93a.68.68 0 0 1-.582-1.031l9.296-15.267ZM9.722 2.72.427 17.987A2.93 2.93 0 0 0 2.93 22.44h18.14a2.93 2.93 0 0 0 2.503-4.453L14.278 2.72a2.669 2.669 0 0 0-4.556 0ZM13.5 17.19a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Zm-.375-8.625c0-.623-.502-1.125-1.125-1.125s-1.125.501-1.125 1.125v4.5c0 .623.502 1.125 1.125 1.125s1.125-.502 1.125-1.125v-4.5Z"
      fill="currentColor"
    />
  </IconBase>
);
