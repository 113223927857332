import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const CouchPulloutIcon = (props: IconProps) => {
  const clipId = useId();

  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M22.8 4.128h-1.2V1.92c0-.672-.528-1.2-1.2-1.2H3.6c-.672 0-1.2.528-1.2 1.2v2.208H1.2c-.672 0-1.2.528-1.2 1.2v6c0 .672.528 1.2 1.2 1.2h1.2v9.552c0 .672.528 1.2 1.2 1.2h16.8c.672 0 1.2-.528 1.2-1.2v-9.552h1.2c.672 0 1.2-.528 1.2-1.2V5.376c0-.672-.528-1.248-1.2-1.248.048 0 0 0 0 0ZM18 7.68h-3.648v-2.4H18v2.4ZM3.6 9.024h16.8v.864H3.6v-.864Zm0-7.104h16.8v5.76h-1.2v-2.4c0-.672-.528-1.2-1.2-1.2h-3.648c-.672 0-1.2.528-1.2 1.2v2.4h-2.256v-2.4c0-.672-.528-1.2-1.2-1.2H6.048c-.72 0-1.248.528-1.248 1.2v2.4H3.6V1.92ZM6 7.68v-2.4h3.6v2.4H6Zm-4.8 3.696v-6h1.2v6H1.2ZM20.4 22.08H3.6V11.088h16.8V22.08Zm2.4-10.704h-1.2v-6h1.2v6Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
