import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const FilterIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M0 3.683C0 2.972.562 2.4 1.26 2.4h21c.698 0 1.26.572 1.26 1.283 0 .71-.562 1.282-1.26 1.282h-21C.562 4.965 0 4.393 0 3.683Zm3.36 8.55c0-.711.562-1.283 1.26-1.283H18.9c.698 0 1.26.572 1.26 1.283 0 .71-.562 1.282-1.26 1.282H4.62c-.698 0-1.26-.572-1.26-1.283Zm11.76 8.55c0 .71-.562 1.282-1.26 1.282h-4.2c-.698 0-1.26-.572-1.26-1.283 0-.71.562-1.282 1.26-1.282h4.2c.698 0 1.26.572 1.26 1.282Z"
      fill="currentColor"
    />
  </IconBase>
);
