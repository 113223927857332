import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const BedQueenIcon = (props: IconProps) => {
  const clipId = useId();

  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M23.424 6V1.2c0-.672-.528-1.2-1.2-1.2h-20.4C1.152 0 .576.528.576 1.2V6c0 .672.528 1.2 1.2 1.2V18c-.672 0-1.2.528-1.2 1.2v2.4c0 .672.528 1.2 1.2 1.2h1.2c0 .672.528 1.2 1.2 1.2.672 0 1.2-.528 1.2-1.2h13.2c0 .672.528 1.2 1.2 1.2.672 0 1.2-.528 1.2-1.2h1.2c.672 0 1.2-.528 1.2-1.2v-2.4c0-.672-.528-1.2-1.2-1.2V7.2c.672 0 1.248-.528 1.248-1.2Zm-1.248 13.488v1.824a.31.31 0 0 1-.288.288H2.112a.31.31 0 0 1-.288-.288v-1.824c0-.144.096-.288.288-.288h19.776c.192 0 .288.144.288.288ZM19.824 9.6c.672 0 1.2.528 1.2 1.2V18H2.976v-7.2c0-.672.528-1.2 1.2-1.2h15.648Zm-15.6-2.976v-2.4c0-.336.288-.576.576-.576h6c.336 0 .576.288.576.576v2.4a.584.584 0 0 1-.576.576h-6a.555.555 0 0 1-.576-.576Zm8.352 0v-2.4c0-.336.288-.576.576-.576H19.2c.336 0 .576.288.576.576v2.4c0 .336-.24.576-.576.576h-6c-.336 0-.624-.24-.624-.576ZM21.6 6h-.576V3.6c0-.672-.528-1.2-1.2-1.2h-15.6c-.672 0-1.248.528-1.248 1.2V6H2.4a.555.555 0 0 1-.576-.576v-3.6c0-.336.192-.576.432-.576h19.536c.24 0 .432.288.432.576v3.6c0 .336-.288.576-.624.576Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
