import { type IconProps } from 'types/Icons';
import { cn } from 'utils/tailwind';

export const IconBase = ({
  className,
  size: sizeProp,
  viewBox = '0 0 24 24',
  ...props
}: IconProps) => {
  const size = sizeProp || '1em';
  return (
    <svg
      className={cn(className)}
      fill="none"
      height={sizeProp || '1em'}
      viewBox={viewBox}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
};
