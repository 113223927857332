import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const CashIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M1.8 6.66c0-.499-.401-.9-.9-.9s-.9.401-.9.9v9a4.5 4.5 0 0 0 4.5 4.5h15c.499 0 .9-.401.9-.9s-.401-.9-.9-.9h-15a2.698 2.698 0 0 1-2.7-2.7v-9Z"
      fill="currentColor"
    />
    <path
      clipRule="evenodd"
      d="M13.8 6.657c.901 0 1.766.348 2.403.966.638.618.996 1.457.996 2.332 0 .874-.358 1.713-.996 2.331a3.455 3.455 0 0 1-2.404.966 3.455 3.455 0 0 1-2.404-.966 3.247 3.247 0 0 1-.996-2.331c0-.875.358-1.714.996-2.332a3.455 3.455 0 0 1 2.404-.966Zm0 5.159c1.06 0 1.918-.834 1.918-1.861 0-1.028-.859-1.862-1.919-1.862s-1.919.834-1.919 1.862c0 1.027.86 1.86 1.92 1.86Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M3.598 5.558c0-1.212 1.017-2.198 2.267-2.198h15.868c1.25 0 2.267.986 2.267 2.198v8.793c0 1.212-1.017 2.198-2.267 2.198H5.865c-1.25 0-2.267-.986-2.267-2.198V5.558Zm2.584-.55c-.542 0-.884.316-.884.941v1.244c1.245-.004 2.253-.98 2.26-2.184H6.183Zm-.884 6.12V8.815C7.17 8.811 9.183 6.823 9.193 5.01h9.181c.011 1.812 2.058 3.758 3.926 3.769v2.312c-1.87.01-3.879 1.998-3.887 3.81h-9.18c-.009-1.816-2.063-3.768-3.935-3.772ZM22.3 7.193c-1.239-.008-2.244-.982-2.252-2.184h1.255c.655 0 .997.329.997.94v1.244ZM20.048 14.9c.006-1.202 1.01-2.18 2.252-2.187v1.166c0 .658-.209 1.021-.997 1.021h-1.255Zm-14.75-2.187c1.244.003 2.255.98 2.261 2.187H6.182c-.67 0-.884-.344-.884-1.021v-1.166Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </IconBase>
);
