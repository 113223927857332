import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const PlusIcon = (props: IconProps) => {
  const clipId = useId();

  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M24 12a1.38 1.38 0 0 1-1.385 1.384h-9.23v9.23a1.385 1.385 0 0 1-2.77 0v-9.23h-9.23a1.384 1.384 0 1 1 0-2.77h9.23v-9.23a1.384 1.384 0 0 1 2.77 0v9.23h9.23c.768 0 1.385.624 1.385 1.385Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
