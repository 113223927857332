import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const ChevronRightIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M8.066.413 18.28 11.075c.249.258.373.592.373.925 0 .333-.124.668-.373.925L8.066 23.587a1.335 1.335 0 0 1-1.891.04 1.332 1.332 0 0 1-.04-1.892l9.372-9.785-9.37-9.685a1.332 1.332 0 0 1 .04-1.892 1.333 1.333 0 0 1 1.889.04Z"
      fill="currentColor"
    />
  </IconBase>
);
