import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const LinkIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M22.328 12.625a5.709 5.709 0 0 0 0-8.074 5.706 5.706 0 0 0-7.793-.258l-.242.214a.954.954 0 0 0 1.254 1.436l.242-.214a3.804 3.804 0 0 1 5.194 5.555l-4.496 4.49a3.804 3.804 0 0 1-5.555-5.194l.187-.213a.954.954 0 0 0-1.436-1.254l-.187.215a5.709 5.709 0 0 0 8.336 7.792l4.496-4.495Zm-20.656-.89a5.704 5.704 0 0 0 0 8.071 5.707 5.707 0 0 0 7.797.258l.242-.214a.954.954 0 0 0-1.254-1.437l-.242.215a3.804 3.804 0 0 1-5.194-5.555l4.496-4.487a3.804 3.804 0 0 1 5.555 5.194l-.215.241a.954.954 0 0 0 1.436 1.254l.215-.242a5.709 5.709 0 0 0-8.332-7.796l-4.504 4.499Z"
      fill="currentColor"
    />
  </IconBase>
);
