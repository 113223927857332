import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const BedKingIcon = (props: IconProps) => {
  const clipId = useId();

  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M22.802 8.543V7.391C23.47 7.391 24 6.873 24 6.24V1.631c0-.634-.541-1.152-1.198-1.152H1.198C.541.478 0 .996 0 1.63v4.608C0 6.872.541 7.39 1.198 7.39v10.37C.541 17.76 0 18.279 0 18.913v2.304c0 .634.541 1.152 1.198 1.152h1.198c0 .634.542 1.152 1.198 1.152.657 0 1.198-.518 1.198-1.152h14.403c0 .634.542 1.152 1.198 1.152.668 0 1.198-.518 1.198-1.152h1.198c.669 0 1.198-.518 1.198-1.152v-2.304c0-.634-.54-1.152-1.198-1.152V8.543h.013Zm0 12.386a.295.295 0 0 1-.3.288H1.498a.295.295 0 0 1-.3-.288v-1.728c0-.161.138-.288.3-.288h21.004c.161 0 .3.127.3.288v1.728ZM21.615 9.695v8.066H2.397V9.695h19.218ZM3.606 6.815V4.511c0-.323.265-.576.6-.576h6.002c.334 0 .6.253.6.576v2.304c0 .323-.277.576-.611.576H4.205a.584.584 0 0 1-.599-.576Zm9.598 0V4.511c0-.323.265-.576.599-.576h6.003c.334 0 .599.253.599.576v2.304a.583.583 0 0 1-.6.576h-6.002a.584.584 0 0 1-.6-.576Zm8.998-.576h-.599V3.935c0-.634-.541-1.152-1.198-1.152H3.606c-.668 0-1.21.518-1.21 1.152v2.304h-.598a.583.583 0 0 1-.6-.576V2.207c0-.323.196-.576.438-.576h20.74c.242 0 .437.253.437.576v3.456a.6.6 0 0 1-.61.576Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
