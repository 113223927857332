import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const SortAscendingIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M4.35 2.702a1 1 0 0 1 1.432 0l4.057 4.114a1.034 1.034 0 0 1 0 1.453 1.004 1.004 0 0 1-1.433 0L6.082 5.912v14.66c0 .57-.452 1.028-1.014 1.028a1.019 1.019 0 0 1-1.015-1.028V5.912L1.73 8.272a1 1 0 0 1-1.433 0 1.038 1.038 0 0 1 0-1.452l4.057-4.114-.004-.004Zm8.493.386h2.029c.562 0 1.014.458 1.014 1.028a1.02 1.02 0 0 1-1.014 1.029h-2.029a1.019 1.019 0 0 1-1.014-1.029c0-.57.452-1.028 1.014-1.028Zm0 5.485h4.733c.563 0 1.015.458 1.015 1.028a1.02 1.02 0 0 1-1.015 1.029h-4.733A1.019 1.019 0 0 1 11.83 9.6c0-.57.452-1.028 1.014-1.028Zm0 5.485h7.438c.562 0 1.014.459 1.014 1.028a1.02 1.02 0 0 1-1.014 1.029h-7.438a1.019 1.019 0 0 1-1.014-1.028c0-.57.452-1.029 1.014-1.029Zm0 5.485h10.143A1.02 1.02 0 0 1 24 20.572c0 .57-.452 1.028-1.014 1.028H12.843a1.019 1.019 0 0 1-1.014-1.028c0-.57.452-1.029 1.014-1.029Z"
      fill="currentColor"
    />
  </IconBase>
);
