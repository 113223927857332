import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const FlagIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M3.69 1.125C3.69.502 3.188 0 2.565 0S1.44.502 1.44 1.125v21.75c0 .623.502 1.125 1.125 1.125s1.125-.502 1.125-1.125v-4.688l3.764-.942a8.336 8.336 0 0 1 5.742.628 8.301 8.301 0 0 0 6.642.347l1.627-.61a1.5 1.5 0 0 0 .975-1.405V3.098a1.451 1.451 0 0 0-2.1-1.298l-.45.225a7.694 7.694 0 0 1-6.895 0 7.712 7.712 0 0 0-5.32-.586l-3.985.998V1.125Zm0 3.633 4.528-1.135a5.488 5.488 0 0 1 3.769.413 9.967 9.967 0 0 0 8.203.319v11.334l-1.144.427a6.081 6.081 0 0 1-4.847-.253 10.586 10.586 0 0 0-7.293-.802l-3.216.806V4.757Z"
      fill="currentColor"
    />
  </IconBase>
);
