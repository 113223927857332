import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const CopyIcon = (props: IconProps) => (
  <IconBase {...props} viewBox="0 0 16 16">
    <path
      d="M7.16675 0H11.0449C11.4417 0 11.823 0.159375 12.1042 0.440625L14.2261 2.5625C14.5074 2.84375 14.6667 3.225 14.6667 3.62188V10.5C14.6667 11.3281 13.9949 12 13.1667 12H7.16675C6.33862 12 5.66675 11.3281 5.66675 10.5V1.5C5.66675 0.671875 6.33862 0 7.16675 0ZM2.16675 4H4.66675V6H2.66675V14H8.66675V13H10.6667V14.5C10.6667 15.3281 9.99487 16 9.16675 16H2.16675C1.33862 16 0.666748 15.3281 0.666748 14.5V5.5C0.666748 4.67188 1.33862 4 2.16675 4Z"
      fill="currentColor"
      id="Filled"
    />
  </IconBase>
);
