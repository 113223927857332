import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const HeartEmptyIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="m10.641 21.5421-.1125-.1035-7.88402-7.3215c-1.3815-1.2825-2.1645-3.0826-2.1645-4.96805v-.1485c0-3.168 2.25-5.886 5.364-6.48 1.773-.342 3.5865.0675 5.03102 1.0845.405.288.783.621 1.125 1.0035.189-.216.3915-.414.6075-.5985.1665-.144.3375-.279.5175-.405 1.4445-1.017 3.258-1.4265 5.031-1.089 3.114.594 5.364 3.3165 5.364 6.4845v.1485c0 1.88545-.783 3.68555-2.1645 4.96805l-7.884 7.3215-.1125.1035c-.369.342-.855.5355-1.359.5355s-.99-.189-1.359-.5355Zm.5985-14.54405c-.018-.0135-.0315-.0315-.045-.0495l-.801-.9-.0045-.0045c-1.03952-1.1655-2.61002-1.6965-4.14002-1.404-2.097.4005-3.609 2.2275-3.609 4.3605v.1485c0 1.28255.5355 2.51095 1.476 3.38395L12 19.8546l7.884-7.3216c.9405-.873 1.476-2.1014 1.476-3.38395v-.1485c0-2.1285-1.512-3.96-3.6045-4.3605-1.53-.2925-3.105.243-4.14 1.404l-.0045.0045-.0045.0045-.801.9c-.0135.018-.0315.0315-.045.0495-.2025.2025-.477.315-.7605.315s-.558-.1125-.7605-.315v-.0045Z"
      fill="currentColor"
    />
  </IconBase>
);
