import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const DollarSignIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M13.154 1.125C13.154.502 12.652 0 12.029 0s-1.125.502-1.125 1.125v2.236c-.145.005-.29.014-.436.028-1.087.09-2.212.347-3.15.942-.975.619-1.687 1.575-1.945 2.897-.183.952-.094 1.819.286 2.588.375.75.98 1.293 1.63 1.696 1.229.76 2.902 1.186 4.356 1.557l.107.028c1.59.403 2.935.755 3.83 1.312.422.263.67.525.802.793.126.248.197.6.093 1.148-.136.69-.628 1.26-1.617 1.636-1.012.384-2.437.51-4.106.276-1.06-.154-2.897-.595-3.89-1.035a1.127 1.127 0 0 0-.915 2.057c1.252.553 3.319 1.032 4.486 1.2h.005c.155.024.31.043.464.057v2.334c0 .623.502 1.125 1.125 1.125s1.125-.502 1.125-1.125v-2.278c.905-.061 1.753-.23 2.508-.516 1.495-.567 2.704-1.65 3.023-3.31.183-.95.094-1.818-.286-2.587-.375-.75-.98-1.293-1.631-1.696-1.228-.76-2.902-1.186-4.355-1.557l-.107-.028c-1.59-.403-2.935-.755-3.83-1.312-.422-.263-.67-.525-.802-.793-.126-.248-.197-.6-.093-1.148.135-.694.473-1.125.946-1.425.511-.323 1.233-.525 2.124-.595 1.795-.146 3.895.248 5.339.586a1.124 1.124 0 1 0 .52-2.19 26.763 26.763 0 0 0-3.351-.585V1.125h-.005Z"
      fill="currentColor"
    />
  </IconBase>
);
