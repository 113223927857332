import { useId } from 'react';

import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const StarIcon = (props: IconProps) => {
  const clipId = useId();
  return (
    <IconBase {...props}>
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M11.996.108c.431 0 .825.243 1.012.632l3.216 6.623 7.18 1.06c.422.06.774.356.905.764a1.13 1.13 0 0 1-.277 1.148L18.825 15.5l1.228 7.293c.07.422-.103.849-.45 1.102a1.12 1.12 0 0 1-1.186.08l-6.421-3.431-6.417 3.426a1.12 1.12 0 0 1-1.186-.08 1.136 1.136 0 0 1-.454-1.101l1.228-7.294-5.208-5.16a1.122 1.122 0 0 1-.276-1.148 1.13 1.13 0 0 1 .904-.764l7.181-1.06L10.983.74a1.126 1.126 0 0 1 1.013-.632Zm0 3.702-2.46 5.072a1.136 1.136 0 0 1-.85.623l-5.544.816 4.026 3.989c.258.257.38.623.319.984l-.952 5.61 4.931-2.634a1.117 1.117 0 0 1 1.06 0l4.93 2.634-.946-5.605a1.117 1.117 0 0 1 .318-.985l4.027-3.989-5.545-.82a1.133 1.133 0 0 1-.849-.623L11.996 3.81Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
