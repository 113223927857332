import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const LocationIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M18.63 9a6.752 6.752 0 0 0-6.75-6.75A6.752 6.752 0 0 0 5.13 9c0 .581.21 1.481.717 2.681.492 1.163 1.19 2.447 1.992 3.746 1.336 2.165 2.883 4.256 4.041 5.746 1.162-1.49 2.71-3.58 4.04-5.746.802-1.299 1.5-2.583 1.993-3.746.506-1.2.717-2.1.717-2.681Zm2.25 0c0 4.097-5.484 11.39-7.89 14.4a1.416 1.416 0 0 1-2.22 0C8.363 20.39 2.88 13.097 2.88 9c0-4.969 4.031-9 9-9s9 4.031 9 9Zm-7.5 0a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0ZM8.13 9a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0Z"
      fill="currentColor"
    />
  </IconBase>
);
