import { type IconProps } from 'types/Icons';

export const BlobIcon = ({ size, ...props }: IconProps) => (
  <svg
    fill="currentColor"
    height={size}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 29 28"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.3104 7.87896C26.3963 5.45811 24.7835 3.34377 22.5747 1.96909C21.3703 1.21955 20.0025 0.682859 18.6094 0.413692C16.6673 0.0387806 14.6466 0.256861 12.7523 0.778716C10.8022 1.31595 8.92712 2.17866 7.23466 3.28472C5.59988 4.35315 4.21092 5.74073 3.04993 7.3269C0.972675 10.1658 0.844134 14.5604 0.788378 15.609C0.696366 17.3314 0.908404 19.1096 1.44894 20.7496C1.94168 22.244 2.76621 23.4854 3.95302 24.5206C5.2398 25.6432 6.76746 26.4537 8.3745 27.0148C11.5894 28.1374 15.2759 28.3447 18.5636 27.4081C21.2215 26.6512 23.4915 24.9942 25.1422 22.793C26.7158 20.6949 27.6952 18.1513 28.0613 15.5615C28.4244 12.9934 28.2308 10.3168 27.3104 7.87896Z"
      fill="#093F45"
    />
  </svg>
);
