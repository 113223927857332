import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const MoneyBillIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      clipRule="evenodd"
      d="M3.04 5.84C2.402 5.84 2 6.223 2 6.98v1.51a2.668 2.668 0 0 0 2.66-2.65H3.04ZM2 13.263v-2.805c2.201-.005 4.57-2.417 4.582-4.618h10.8c.013 2.198 2.42 4.56 4.618 4.572v2.806c-2.2.012-4.563 2.423-4.572 4.622h-10.8c-.01-2.203-2.426-4.571-4.628-4.577ZM22 8.49a2.668 2.668 0 0 1-2.65-2.649h1.477c.77 0 1.173.4 1.173 1.14v1.51Zm-2.65 9.351A2.668 2.668 0 0 1 22 15.186V16.6c0 .799-.245 1.24-1.173 1.24H19.35ZM2 15.186a2.668 2.668 0 0 1 2.66 2.654H3.04c-.79 0-1.04-.418-1.04-1.24v-1.414Zm-2-8.68A2.67 2.67 0 0 1 2.667 3.84h18.666A2.67 2.67 0 0 1 24 6.507v10.666a2.67 2.67 0 0 1-2.667 2.667H2.667A2.67 2.67 0 0 1 0 17.173V6.507ZM12 7.84a4 4 0 1 1 0 8 4 4 0 0 1 0-8Zm0 6.258a2.258 2.258 0 1 0 0-4.516 2.258 2.258 0 0 0 0 4.516Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </IconBase>
);
