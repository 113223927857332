'use client';

import { useId } from 'react';

import { type IconProps } from 'types/Icons';

export const PercentageIcon = ({ size, ...props }: IconProps) => {
  const clipId = useId();

  return (
    <svg
      fill="currentColor"
      height={size}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 17 18"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${clipId})`}>
        <path d="M2.54856 8.32928C3.01573 8.54183 3.5005 8.65065 3.97213 8.65065C4.11509 8.65065 4.25712 8.6407 4.39727 8.62032C5.0567 8.52493 5.67458 8.19869 6.18307 7.67704C6.81691 7.02759 7.22116 6.19499 7.32164 5.33252C7.43127 4.39133 7.16106 3.43671 6.57981 2.71385C5.98188 1.96993 4.98933 1.51219 3.85592 1.45801C1.47502 1.33969 0.392552 3.16442 0.176106 3.875C0.0061416 4.43208 0.0176447 5.05444 0.209911 5.67473C0.562985 6.81643 1.45929 7.83356 2.54856 8.32928ZM1.44285 4.26213C1.44755 4.24685 1.97223 2.77868 3.65121 2.77868C3.69746 2.77868 3.74488 2.77961 3.79301 2.78193C4.53672 2.81758 5.19286 3.1026 5.54781 3.5446C5.90793 3.99239 6.07484 4.58813 6.00606 5.17878C5.93939 5.7516 5.6659 6.30983 5.23559 6.75067C4.93627 7.05746 4.57146 7.25542 4.20806 7.30798C3.85498 7.35938 3.48055 7.29663 3.09695 7.12206C2.34127 6.77846 1.7201 6.07343 1.47502 5.28204C1.36351 4.92224 1.35177 4.55989 1.44285 4.26213Z" />
        <path d="M12.6356 10.3712C10.2544 10.2531 9.17198 12.0776 8.9553 12.7884C8.78533 13.3455 8.79707 13.9679 8.98934 14.5881C9.34241 15.7298 10.2387 16.7468 11.328 17.2425C11.7952 17.455 12.2802 17.5638 12.7518 17.5638C12.8948 17.5638 13.0368 17.5539 13.1769 17.5335C13.8364 17.4381 14.4542 17.1119 14.9627 16.5902C15.5966 15.9408 16.0011 15.1082 16.1013 14.2457C16.2109 13.3045 15.9407 12.3499 15.3597 11.627C14.7615 10.8831 13.769 10.4251 12.6356 10.3712ZM14.7855 14.0922C14.7188 14.665 14.4453 15.2232 14.015 15.6641C13.7157 15.9709 13.3509 16.1688 12.9875 16.2214C12.6344 16.2726 12.26 16.2101 11.8764 16.0355C11.1207 15.6916 10.4995 14.9866 10.2544 14.1955C10.1429 13.8354 10.1312 13.4731 10.2223 13.1756C10.227 13.1603 10.7517 11.6921 12.4306 11.6921C12.4769 11.6921 12.5243 11.693 12.5724 11.6956C13.3164 11.7312 13.9723 12.0163 14.3272 12.4583C14.6874 12.9058 14.8543 13.5016 14.7855 14.0922Z" />
        <path d="M16.4057 1.82401C15.6186 1.68555 14.8338 1.5427 14.0506 1.39405C12.7855 1.16136 11.5251 0.917321 10.276 0.675829C9.87337 0.597571 9.47546 0.850407 9.38578 1.24193L9.0752 2.60475C8.97167 3.05231 9.26159 3.48852 9.72148 3.57812C10.4598 3.72121 11.2026 3.86453 11.9477 4.00554C11.9101 4.04004 11.8719 4.075 11.8345 4.10927C11.4852 4.43041 11.1357 4.75155 10.7838 5.06967C7.46195 8.07547 3.85068 10.8939 0.331906 13.6198C0.011463 13.868 -0.0451135 14.3269 0.204198 14.6455L1.07374 15.7571C1.3592 16.122 1.88717 16.1882 2.25363 15.9041C5.79048 13.1639 9.41911 10.3285 12.7973 7.27227C12.9898 7.09816 13.1809 6.92196 13.3724 6.74668C13.3724 6.74738 13.3722 6.74807 13.3722 6.74877C13.3079 7.25907 13.2365 7.76683 13.1536 8.2681C13.0917 8.65963 13.3654 9.03564 13.7654 9.1139L15.2812 9.41166C15.686 9.48991 16.0679 9.22805 16.1322 8.83421C16.3186 7.68417 16.452 6.53159 16.583 5.41049C16.6036 5.2292 16.6243 5.04583 16.6473 4.86453C16.6794 4.58252 16.714 4.30075 16.7485 4.01874C16.8102 3.52927 16.8752 3.03888 16.9445 2.54802C16.9935 2.20466 16.7564 1.88583 16.4057 1.82401Z" />
      </g>
      <defs>
        <clipPath id={clipId}>
          <rect
            fill="white"
            height="16.9021"
            transform="translate(0.0488281 0.661743)"
            width="16.9021"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
