import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const BedCaliforniaKingIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M22.804 8.852v-1.05c.66 0 1.196-.472 1.196-1.049V2.556c0-.577-.535-1.05-1.196-1.05H1.196C.536 1.506 0 1.978 0 2.556v4.197c0 .577.535 1.05 1.196 1.05v9.444c-.66 0-1.196.472-1.196 1.05v2.099c0 .577.535 1.05 1.196 1.05h1.196c0 .576.536 1.048 1.196 1.048.661 0 1.197-.472 1.197-1.049h14.418c0 .577.536 1.05 1.197 1.05.66 0 1.196-.473 1.196-1.05h1.196c.66 0 1.196-.472 1.196-1.05v-2.098c0-.577-.535-1.05-1.196-1.05V8.852h.012Zm0 11.281c0 .147-.136.263-.304.263h-21c-.168 0-.304-.115-.304-.263V18.56c0-.147.136-.262.304-.262h21.01c.168 0 .304.115.304.262v1.574h-.01ZM21.597 9.901v7.346H2.403V9.901h19.194ZM3.6 7.278v-2.1c0-.293.272-.524.598-.524H10.2c.336 0 .598.231.598.525v2.099c0 .294-.273.525-.598.525H4.198c-.326 0-.598-.231-.598-.525Zm9.601 0v-2.1c0-.293.273-.524.598-.524h6.003c.336 0 .598.231.598.525v2.099c0 .294-.273.525-.598.525H13.8c-.325 0-.599-.231-.599-.525Zm9.004-.525h-.598V4.654c0-.577-.535-1.05-1.196-1.05H3.6c-.661 0-1.197.473-1.197 1.05v2.1h-.598c-.336 0-.609-.232-.609-.525V3.08c0-.293.2-.524.44-.524h20.737c.242 0 .43.23.43.524v3.15c0 .293-.262.524-.598.524Z"
      fill="currentColor"
    />
  </IconBase>
);
