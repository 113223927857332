import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const BedDoubleTwinIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      d="M9.53 16.332V8.534c.478 0 .867-.39.867-.866V4.202a.869.869 0 0 0-.866-.866H.866A.868.868 0 0 0 0 4.202v3.466c0 .477.39.866.866.866v7.798a.87.87 0 0 0-.866.866v1.733c0 .477.39.866.866.866h.867c0 .477.39.867.866.867a.87.87 0 0 0 .867-.867H6.93c0 .477.39.867.867.867a.87.87 0 0 0 .866-.867h.866a.87.87 0 0 0 .867-.866v-1.733a.869.869 0 0 0-.866-.866Zm-.866-6.065v6.065H1.733v-6.065h6.931ZM2.599 8.534V6.368a.43.43 0 0 1 .434-.433h4.332a.43.43 0 0 1 .433.433v2.166H2.6ZM.866 4.635c0-.242.14-.433.312-.433h8.04c.174 0 .313.19.313.433v2.6a.43.43 0 0 1-.434.433h-.433V5.935a.869.869 0 0 0-.866-.866H2.599a.869.869 0 0 0-.866.866v1.733H1.3a.43.43 0 0 1-.434-.434V4.635Zm8.665 14.08a.214.214 0 0 1-.217.216H1.083a.214.214 0 0 1-.217-.216v-1.3c0-.122.096-.217.217-.217h8.23c.122 0 .217.096.217.217v1.3Zm13.603-2.383V8.534c.476 0 .866-.39.866-.866V4.202a.869.869 0 0 0-.866-.866h-8.665a.869.869 0 0 0-.866.866v3.466c0 .477.39.866.866.866v7.798a.869.869 0 0 0-.866.866v1.733c0 .477.39.866.866.866h.867c0 .477.39.867.866.867.477 0 .866-.39.866-.867h3.466c0 .477.39.867.866.867a.87.87 0 0 0 .867-.867h.866a.87.87 0 0 0 .867-.866v-1.733a.863.863 0 0 0-.866-.866Zm-.867-6.065v6.065h-6.931v-6.065h6.931Zm-6.065-1.733V6.368a.43.43 0 0 1 .434-.433h4.332a.43.43 0 0 1 .433.433v2.166h-5.199ZM14.47 4.635c0-.242.139-.433.312-.433h8.04c.174 0 .313.19.313.433v2.6a.43.43 0 0 1-.434.433h-.433V5.935a.869.869 0 0 0-.866-.866h-5.199a.869.869 0 0 0-.866.866v1.733h-.433a.43.43 0 0 1-.434-.434V4.635Zm8.665 14.08a.214.214 0 0 1-.217.216h-8.23a.214.214 0 0 1-.217-.216v-1.3c0-.122.095-.217.216-.217h8.231c.122 0 .217.096.217.217v1.3Z"
      fill="currentColor"
    />
  </IconBase>
);
