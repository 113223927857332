import { type IconProps } from 'types/Icons';

import { IconBase } from './IconBase';

export const LoadingIcon = (props: IconProps) => (
  <IconBase {...props}>
    <g clipPath="url(#a)" strokeLinecap="round" strokeWidth="2.25">
      <path d="M12.375 1.125v3.75">
        <animate
          attributeName="stroke-opacity"
          begin="0s"
          calcMode="linear"
          dur="1s"
          repeatCount="indefinite"
          values="1;0.9;0.8;0.7;0.6;0.5;0.4;0.3"
        />
      </path>
      <path d="M20.03 4.629 17.38 7.28" strokeOpacity=".7">
        <animate
          attributeName="stroke-opacity"
          begin="0s"
          calcMode="linear"
          dur="1s"
          repeatCount="indefinite"
          values="0.3;1;0.9;0.8;0.7;0.6;0.5;0.4"
        />
      </path>
      <path d="M22.875 12.375h-3.75" strokeOpacity="0">
        <animate
          attributeName="stroke-opacity"
          begin="0s"
          calcMode="linear"
          dur="1s"
          repeatCount="indefinite"
          values="0.4;0.3;1;0.9;0.8;0.7;0.6;0.5"
        />
      </path>
      <path d="m19.5 19.902-2.652-2.652" strokeOpacity="0">
        <animate
          attributeName="stroke-opacity"
          begin="0s"
          calcMode="linear"
          dur="1s"
          repeatCount="indefinite"
          values="0.5;0.4;0.3;1;0.9;0.8;0.7;0.6"
        />
      </path>
      <path d="M12.375 19.125v3.75" strokeOpacity="0">
        <animate
          attributeName="stroke-opacity"
          begin="0s"
          calcMode="linear"
          dur="1s"
          repeatCount="indefinite"
          values="0.6;0.5;0.4;0.3;1;0.9;0.8;0.7"
        />
      </path>
      <path d="m7.409 17.25-2.652 2.652" strokeOpacity="0">
        <animate
          attributeName="stroke-opacity"
          begin="0s"
          calcMode="linear"
          dur="1s"
          repeatCount="indefinite"
          values="0.7;0.6;0.5;0.4;0.3;1;0.9;0.8"
        />
      </path>
      <path d="M4.875 12.375h-3.75" strokeOpacity="0">
        <animate
          attributeName="stroke-opacity"
          begin="0s"
          calcMode="linear"
          dur="1s"
          repeatCount="indefinite"
          values="0.8;0.7;0.6;0.5;0.4;0.3;1;0.9"
        />
      </path>
      <path d="M6.75 7.152 4.098 4.5" strokeOpacity="0">
        <animate
          attributeName="stroke-opacity"
          begin="0s"
          calcMode="linear"
          dur="1s"
          repeatCount="indefinite"
          values="0.9;0.8;0.7;0.6;0.5;0.4;0.3;1"
        />
      </path>
    </g>
    <defs>
      <clipPath id="a">
        <path d="M24 0H0v24h24z" fill="#fff" />
      </clipPath>
    </defs>
  </IconBase>
);

export default LoadingIcon;
